/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 */


import { useStore } from '@/store'
import HttpClient, { HttpClientConfig } from 'axios-mapper'
import networkConfig from '@/config/default/net.config'
const https = (hasToken: Boolean = true, token?: string) => {
  const config: HttpClientConfig = {
    baseURL: networkConfig.host,
    headers: {
      Authorization: hasToken ? useStore().state.user.token : '',
      token
    }
  }
  return new HttpClient(config)
}

export default https
