/*
 * @Description:
 * @Autor: ZY
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-02-29 20:06:46
 */
import { createApp, Directive } from 'vue'
import App from './App.vue'
// import './pwa/registerServiceWorker'
import router from './router'
import { store } from './store'
import { loadAllPlugins } from './plugins'
import '@/assets/iconfont/iconfont.css'
import '@/styles/index.scss'
import 'normalize.css'
import 'font-awesome/css/font-awesome.min.css'
import * as directives from '@/directives'
import '@/permission'
import i18n from '@/locales/index'
import { getLanguage } from '@/utils/cookies'
import moment from 'moment'
import '@/styles/reset.css';


const app = createApp(App)
// 加载所有插件
app.use(i18n)
loadAllPlugins(app) 
moment.locale(getLanguage())

// 自定义指令
Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string ]: Directive })[key])
})

app.use(store).use(router).mount('#app');