/*
 * @Author: your name
 * @Date: 2022-03-18 14:29:48
 * @LastEditTime: 2022-07-12 14:09:32
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\locales\zh-cn\global.ts
 */
export default {
  'global.edit': 'Edit',
  'global.deleteFail': 'Failed to delete',
  'global.editSuccess': 'Edited successfully',
  'global.createSuccess': 'Created successfully',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.error400':'Parameter error',
  'global.error500':'Operation failed',
  'global.error20004':'Authentication error, invalid token',
  'global.error30002':'Server query problem',
  'global.error40003':'Account password error',
  'global.error40008':'Verification code error',
  'global.error40009':'Mail delivery failure'
}