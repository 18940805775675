/*
 * @Author: your name
 * @Date: 2022-03-18 14:08:19
 * @LastEditTime: 2022-07-12 14:06:50
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\locales\system.ts
 */
export default {
  // 'system.tab2': 'Security setting',
	'system.tab2': 'Change Password',
	'system.tab3': 'Security question',
	'system.password.item1': 'Current password:',
	'system.password.item2': 'New password:',
	'system.password.item3': 'Confirm password:',
	'system.password.pleaseEnter': 'Please input',
	'system.password.rule': 'Password rules:',
	'system.password.rule1': '1. The password length is 6-20 digits',
	'system.password.rule2': '2. The password supports numbers, upper and lower case letters, and special symbols',
	'system.password.confirmPasswordempty': 'The confirm password cannot be empty',
	'system.password.confirmPasswordRule1': 'The confirm password cannot be more than 20 digits',
	'system.password.confirmPasswordRule2': 'The confirm password cannot be less than 6 digits',
	'system.password.passwordRule3': 'The password supports numbers, English case and special symbols',
	'system.password.passwordRule4': 'The new password is inconsistent with the confirm new password',
	'system.password.passwordempty': 'New password cannot be empty',
	'system.password.passwordRule1': 'New password cannot be more than 20 digits',
	'system.password.passwordRule2': 'New password cannot be less than 6 digits',
	'system.password.currentPasswordEmpty': 'Current password cannot be empty',
	'system.password.qaTips': 'You changed your initial password, so we need you to set a security question. If you forget your password, you can reset it by answering the security question.',
	'system.password.setQa': 'Set security question',
	'system.password.toSet': 'Go to setting',
	'system.password.changePasswordSuccess': 'Password modified successfully',
	'system.password.passwordWrong': 'The current password is incorrect',
	'system.password.changePasswordError': 'Password modification failed',
	
	'system.qa.qaTips2': 'You are modifying the security question. Please answer the original security question first:',
	'system.qa.qaTips3': 'You are selecting a security question. If you forget your password, you can reset it by answering the security question.',
	'system.qa.enterAnswer': 'Please enter the answer',
	'system.qa.nextStep': 'Next',
	'system.qa.currentAnswer': 'The answer cannot be empty',
	'system.qa.answerError': 'Wrong answer',
	'system.qa.failRequeat': 'Request error',
	'system.qa.setQaSuccess': 'Security question set successfully',
	'system.qa.setQaError': 'Security question setting failed',
	'system.qa.getQaListError': 'Failed to get current security question',
	'system.qa.choiceQa': 'Please select a security question:',

	'system.forgetPassword.title': 'Forgot password',
	'system.forgetPassword.findPassword': 'Find password',
	'system.forgetPassword.tip1': 'If you have not changed your password, please use the initial password',
	'system.forgetPassword.tip2': 'If you have changed your password, you can reset your password by answering the security question. Click "Next" to answer the security question',
	'system.forgetPassword.enterEnterprise': 'Please enter your enterprise number:',
	'system.forgetPassword.pleaseQa': 'Please answer the security question',
	'system.forgetPassword.correct': 'Correct answer',
	'system.forgetPassword.resetPassword': 'The login password has been reset to 12345678',
	'system.forgetPassword.noQa': 'The enterprise number has no security question.',
	'system.forgetPassword.noQaTip': 'If you have any questions, please contact the after-sales.',
	'system.forgetPassword.enterpriseEmpty': 'Enterprise number cannot be empty',
	'system.forgetPassword.enterpriseNoexistence': 'Enterprise number does not exist',
	'system.forgetPassword.wrongEnterprise': 'Enterprise number error',
	'system.forgetPassword.answerEmpty': 'The answer cannot be empty',
	'system.forgetPassword.findPasswordError': 'Failed to reset password',
	'system.forgetPassword.noQaTip1': 'If you want to change the initial password, we need you to set a security question first.',
  'system.forgetPassword.noQaTip2': 'If you forget your password later, you can reset it by answering the security question.',
	
	'system.back': 'Back',
	'system.language': 'Language',
	'system.remark': 'remarks',

	'system.forgetPassword.isAdminTip': 'Are you the admin user?',
  'system.forgetPassword.isAdminStep1': 'If you are admin, you can reset your password by answering a security question.',
  'system.forgetPassword.userRule': 'If you forget your password, please contact your administrator to change the password.',
}