export default {
	'system.tab2': 'Cambiar Contraseña',
	'system.tab3': 'Pregunta de seguridad',
	'system.password.item1': 'Contraseña actual:',
	'system.password.item2': 'Nueva contraseña:',
	'system.password.item3': 'Confirmar contraseña:',
	'system.password.pleaseEnter': 'Por favor, ingrese',
	'system.password.rule': 'Reglas de contraseña:',
	'system.password.rule1': '1. La contraseña es entre 6 a 20 caracteres',
	'system.password.rule2': '2. La contraseña contiene números, letras mayúsculas y minúsculas, y símbolos especiales',
	'system.password.confirmPasswordempty': 'La confirmación de contraseña no puede estar vacía',
	'system.password.confirmPasswordRule1': 'La confirmación de la contraseña no puede tener más de 20 caracteres',
	'system.password.confirmPasswordRule2': 'La confirmación de la contraseña no puede tener menos de 6 caracteres',
	'system.password.passwordRule3': 'La contraseña contiene números, letras mayúsculas, minúsculas y símbolos especiales',
	'system.password.passwordRule4': 'La nueva contraseña no coincide con la confirmación de la nueva contraseña',
	'system.password.passwordempty': 'La nueva contraseña no puede estar vacía',
	'system.password.passwordRule1': 'La nueva contraseña no puede tener más de 20 caracteres',
	'system.password.passwordRule2': 'La nueva contraseña no puede tener menos de 6 caracteres',
	'system.password.currentPasswordEmpty': 'La contraseña actual no puede estar vacía',
	'system.password.qaTips': 'Cambio su contraseña inicial, así que necesitaremos que configure una pregunta de seguridad. Si olvida su contraseña, puede restablecerla respondiendo la pregunta de seguridad.',
	'system.password.setQa': 'Establecer pregunta de seguridad',
	'system.password.toSet': 'Ir a ajustes',
	'system.password.changePasswordSuccess': 'Contraseña modificada correctamente',
	'system.password.passwordWrong': 'La contraseña actual es incorrecta',
	'system.password.changePasswordError': 'Falló la modificación de la contraseña',
	'system.qa.qaTips2': 'Está modificando la pregunta de seguridad. Por favor, responde primero la pregunta de seguridad original:',
	'system.qa.qaTips3': 'Está seleccionando una pregunta de seguridad. Si olvida su contraseña, puede restablecerla respondiendo la pregunta de seguridad.',
	'system.qa.enterAnswer': 'Por favor, ingresa la respuesta',
	'system.qa.nextStep': 'Siguiente',
	'system.qa.currentAnswer': 'La respuesta no puede estar vacía',
	'system.qa.answerError': 'Respuesta incorrecta',
	'system.qa.failRequeat': 'Error de solicitud',
	'system.qa.setQaSuccess': 'Pregunta de seguridad configurada con éxito',
	'system.qa.setQaError': 'Error al configurar la pregunta de seguridad',
	'system.qa.getQaListError': 'Error al obtener la pregunta de seguridad actual',
	'system.qa.choiceQa': 'Por favor, selecciona una pregunta de seguridad:',
	'system.forgetPassword.title': 'Olvidé la contraseña',
	'system.forgetPassword.findPassword': 'Encontrar contraseña',
	'system.forgetPassword.tip1': 'Si no has cambiado tu contraseña, usa la contraseña inicial',
	'system.forgetPassword.tip2': 'Si ha cambiado su contraseña, puede restablecerla respondiendo la pregunta de seguridad. Haga clic en "Siguiente" para responder la pregunta de seguridad.',
	'system.forgetPassword.enterEnterprise': 'Por favor, ingresa tu número de empresa:',
	'system.forgetPassword.pleaseQa': 'Por favor, responde la pregunta de seguridad',
	'system.forgetPassword.correct': 'Respuesta correcta',
	'system.forgetPassword.resetPassword': 'La contraseña de inicio de sesión se ha restablecido a 12345678',
	'system.forgetPassword.noQa': 'El número de empresa no tiene una pregunta de seguridad.',
	'system.forgetPassword.noQaTip': 'Si tiene alguna pregunta, por favor contacte al servicio postventa.',
	'system.forgetPassword.enterpriseEmpty': 'El número de empresa no puede estar vacío',
	'system.forgetPassword.enterpriseNoexistence': 'El número de empresa no existe',
	'system.forgetPassword.wrongEnterprise': 'Error en el número de empresa',
	'system.forgetPassword.answerEmpty': 'La respuesta no puede estar vacía',
	'system.forgetPassword.findPasswordError': 'Error al restablecer la contraseña',
	'system.forgetPassword.noQaTip1': 'Si desea cambiar la contraseña inicial, primero necesita configurar una pregunta de seguridad.',
	'system.forgetPassword.noQaTip2': 'Si olvida su contraseña más tarde, podrá restablecerla respondiendo la pregunta de seguridad.',
	'system.back': 'Atrás',
	'system.language': 'Idioma',
	'system.remark': 'Observaciones',
	'system.forgetPassword.isAdminTip': '¿Es el usuario administrador?',
	'system.forgetPassword.isAdminStep1': 'Si es administrador, puede restablecer su contraseña respondiendo una pregunta de seguridad.',
	'system.forgetPassword.userRule': 'Si olvida su contraseña, por favor contacte a su administrador para cambiar la contraseña.'
};
