<template>
  <div class="sidebar-logo-container" :class="[collapse ? 'collapse' : 'notitle']">
    <transition name="sidebarLogoFade">
      <router-link v-if="false" key="collapse" class="sidebar-logo-link" to="/">
        <img src="favicon.ico" class="sidebar-logo" />
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img class="sidebar-logo" :src="Logo" />
        <h1 class="sidebar-title" :style="{ fontSize:logoSize }">
          {{ $t("robot.projectTtitle") }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import settings from "@/config/default/setting.config";
import Logo from "@/assets/images/home/logo.png";
export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useStore();
    const title = settings.title;
    const language = computed(() => {
      return store.state.app.language;
    });
    const logoSize = computed((): string => {
      switch (store.state.app.language) {
        case "en":
          return "13px";
        case 'es':
          return "12px";
        default:
          return "16px";
      }
    });
    return {
      title,
      Logo,
      language,
      logoSize
    };
  }
});
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: $menuBg;
  text-align: center;
  overflow: hidden;
  // margin-bottom: 30px;
  // margin-top: 10px;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .sidebar-logo {
      display: inline-block;
      height: 30px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      color: #fff;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 12px;
    }
  }

  &.notitle {
    .sidebar-title {
      display: none;
    }

    & .sidebar-logo {
      height: 60%;
    }
  }
}
</style>
