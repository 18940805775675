/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-05-30 13:59:48
 * @LastEditTime: 2022-04-28 10:34:10
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { commonRequest } from './index'

export const loginRequest = (userInfo: RequestParams) => {
  return https(false).request<RootObject<LoginModel>>('auth', Method.POST, userInfo, ContentType.json)
}

export const userInfoRequest = () => {
  return commonRequest('current_user', Method.GET, undefined, ContentType.form)
}

export const getUsers = (user: any) => {
  return https().request<RootObject<Users>>('user/getUsers', Method.GET, user, ContentType.form)
}
// 获取机器人列表
export const getRobotList = (params: any, isError?: boolean) => {
  return commonRequest('robots', Method.GET, params, ContentType.form, isError)
}
// 语言设置
export const setLang = (params: any) => {
  return commonRequest('language/set', Method.PUT, params, ContentType.json)
}
// 机器人权限编辑
export const robotPermission = (params: any) => {
  return commonRequest('permission/edit', Method.PUT, params, ContentType.json)
}
export const langInfo = (params: any) => {
  return commonRequest('language/get', Method.GET, params, ContentType.form)
}
export const robotPermissionInfo = (params: any) => {
  return commonRequest('permission/edit', Method.GET, params, ContentType.form)
}

export const getPassQuestionList = () => {
  return commonRequest('password/questions', Method.GET, {}, ContentType.json)
}

export const getUserPassQa = (params: any) => {
  return commonRequest('users/password/manage/'+params.name, Method.GET, params, ContentType.json)
}

export const getEmailUserPassQa = (params: any) => {
  return commonRequest('users/password/emailqa?email='+params.email, Method.GET, params, ContentType.json)
}

export const repassword = (params: any, token) => {
  return https(undefined,token).request('password/repassword', Method.POST, params, ContentType.json)
}

export const editUserPassword = (name:string, params: any) => {
  return commonRequest('users/password/'+name, Method.PUT, params, ContentType.json)
}

export const editEmailUserPassword=(params:any)=>{
  return commonRequest("users/password/changepwd",Method.POST,params,ContentType.json);
}

export const checkCompany = (params: any) => {
  return commonRequest('password/check', Method.POST, params, ContentType.json)
}

export const checkQa = (name:string, params: any) => {
  return commonRequest('users/password/manage/'+name, Method.POST, params, ContentType.json)
}

export const addUserQa = (username:string, params: any) => {
  return commonRequest('users/password/manage/'+username, Method.PUT, params, ContentType.json)
}

export const editUserQa = (username:string, params: any, AccessToken: string) => {
  return https(undefined,AccessToken).request('users/password/manage/edit/'+username, Method.PUT, params, ContentType.json)
}

export const getAllUser = (params: any) => { // 获取所有用户
  return commonRequest(`users?userName=${params.userName}&pageSize=${params.pageSize}&page=${params.page}&authority=${params.authority}&negative=${params.negative}`, Method.GET, params, ContentType.json)
}

export const searchUser = (params: any) => { // 获取所有用户
  return commonRequest(`users/search`, Method.POST, params, ContentType.json)
}

export const addUser = (params: any) => { // 新增用户
  return commonRequest('users', Method.POST, params, ContentType.json)
}

export const delUser = (params: any) => { // 删除用户
	//return commonRequest('users/'+params.name, Method.DELETE, {}, ContentType.form)
  return commonRequest('users/del', Method.DELETE, params, ContentType.json)
}

export const getOneUser = (params: any) => { // 获取单个用户
  return commonRequest(`users/`+params.name, Method.GET, params, ContentType.json)
}

export const editUser = (params: any) => { // 编辑用户
  //return commonRequest(`users/`+params.username, Method.PUT, params, ContentType.json)
  return commonRequest('users/update',Method.PUT,params,ContentType.json);
}
// 设置停用和恢复用户
export const blockUPuser= (params: any) => { // 设置停用和恢复用户
  return commonRequest('users/set', Method.PUT, params, ContentType.json)
}
// 获取隐私协议地址
export const getprivacy = () => { // 编辑用户
  return commonRequest(`privacy`, Method.GET, ContentType.json)
}

// 用邮箱登录
export const loginEmailRequest=(data:{[key:string]:any})=>{
  return https(false).request<RootObject<LoginModel>>('email_login', Method.POST, data, ContentType.json)
};

// 新增邮箱用户
export const addEmailUser=(data:{[key:string]:any})=>{
  return commonRequest("users/email_regist",Method.POST,data,ContentType.json);
};