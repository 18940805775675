/*
 * @Author: ying.hong ying.hong@ubtrobot.com
 * @Date: 2022-07-08 15:07:32
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-04-19 17:23:12
 * @FilePath: \ADIS\src\locales\en\user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  'user.name': 'Username',
  'user.placeHolder': 'Please input username',
  'user.title': 'User',
  'user.addUser': 'Create user',
  'user.remark': 'Notes',
  'user.noUser': 'You have not created any users.',
  'user.editUser': 'Edit user',
  'user.rule1': 'Setting rules: Password length is 6-20 characters and supports numbers, uppercase and lowercase letters, and special symbols.',
  'user.ruleTitle': 'Setting rules: ',
  'user.rule2': '1. Username and password length are 6-20 characters.',
  'user.rule3': '2. Username and password support numbers, uppercase and lowercase letters, and special symbols.',
  'user.passwordRule1': 'Password cannot be empty.',
  'user.passwordRule2': 'Password length 6-20 digits',
  'user.passwordRule3': 'Support numbers and English upper and lower case, and support special symbols.',
  'user.usernameRule1': 'Username cannot be empty.',
  'user.usernameRule2': 'Username length 6-20 digits',
  'user.usernameRule3': 'Support numbers and English upper and lower case.',
  'user.delUserConfiem': 'Are you sure you to delete this user?',
  'user.delUser': 'Delete users',
  'user.edieUserTips': 'Please note:<br/>The robot needs to be online to synchronize the new user information. If you cannot log in because the robot is not connected to the internet, you can first log in with the admin account and connect the robot to the internet, you can first log in with the admin account and connect the robot to the Internet.',
  'user.repeatUser': 'The username already exists in the enterprise, please rename it.',
  'user.operator': 'Operator',
  'user.donotcontain': 'Username cannot contain special symbols',
  'user.arearoom': 'Area/Room',
  'user.leave': '(left)',
  'user.cong': 'From',
  'user.dao': 'To',
  'user.adduser': 'Add operator',
  'user.createrdoperatoruser': 'The new operator will have no user creation authority.',
  'user.DisableUser': 'User disabled, please contact administrator',
  'user.jurisdiction': 'Permission',
  'user.userstatus': 'Status',
  'user.createduser': 'Full access',
  'user.blockup': 'Deactivated',
  'user.recover': 'Restore',
  'user.normal': 'Active',
  'user.RestoringaUser': 'Restore user',
  'user.recoveruser': 'Are you sure you want to restore this user?',
  'user.Successfulrecovery': 'Restored successfully',
  'user.failedrecovery': 'Recovery failed',
  'user.notapplicableuser': 'Are you sure you want to deactivate this user? The account is inactive while deactivated but can be used once the user is restored.',
  'user.DeactivatingaUser': 'Disable user',
  'user.Deactivatingasuccessful': 'Disabled successfully',
  'user.Deactivatingafailed': 'Disable failed',
  'user.timecalorders': 'Sort by time',
  'user.alphabeticalorder': 'Sort by name',
  'user.blockuped': 'Deactive',
  'user.email':'Mailbox',
  'user.emailRule1':'Mailbox cannot be null',
  'user.emailRule2':'Please enter the correct mailbox format',
  'user.loginEmail':'Email login',
  'user.loginPassword':'Password Login',
  'email.placeHolder': 'Please enter a mailbox',
  'user.tipsemailexists':'Mailbox is already in use',
  'user.tipselseerror':'Other error',
  'user.tipsinterneterror':'Network error',
  'user.tipscreateemailsuccess': 'Creating mailbox user succeeded',
  'user.tipsverifycodeerror': 'Verify code input error',
  'user.tipsresendemail': 'Mail has been resent',
  'user.emailrule1':'Setting rules: Password length is 6-20 characters and supports numbers, uppercase and lowercase letters, and special symbols.',
  'user.emailrule2':'1. Username and password support numbers, uppercase and lowercase letters, and special symbols.',
  'user.resend':'Resend',
  'user.complete':'Complete',
  'user.emailregisttip':'Please enter the verification code received by your email address to complete the registration. If you do not receive the verification code, you can resend it after %ds.',
  'user.emailLogintip1':' We sent a confirmation email to your email address. Please enter the verification code received in the email for login verification. ',
  'user.emailLogintip2':' If you do not receive the verification code, you can resend it after %ds. ',
  'user.noverifyday30':' No longer verified for 30 days ',
  'user.loginConfirm':' Confirm',
  'user.name_or_email':'Username/Email',
	'user.name_or_email_placeholder':'Please enter username/email'
}