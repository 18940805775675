/*
 * @Author: your name
 * @Date: 2022-04-12 14:06:10
 * @LastEditTime: 2022-06-07 16:30:06
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\apis\index.ts
 */
import https from '@/utils/https'
import { useRouter, } from "vue-router";
import { ElMessage } from 'element-plus'
const router = useRouter();


export const commonRequest = (url: string, method, params?: any, requestFormat?: any, isError?: boolean) => {

  return https().request(url, method, params, requestFormat).then((res: any) => {
    if(res?.code === 20001 || res?.code === 20002){
      router.push('/login')
    }
    return res
  }).catch((err: any) => {
    if (err.response.data.code===401) {
      isError ? ElMessage.error('Request Error '+String(err.response.data.code)) : null
    }
    if (err.response.data.code===20002 || err.response.data.code===20001) {
      router.push('/login')
    }
    
    return err.response.data
  })
}