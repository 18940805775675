export default {
  'route.dashboard': '数据报表',
  'route.robot': '机器人',
  'route.disinfect': '消杀任务',
  'route.errorPages': '错误页面',
  'route.page401': '401',
  'route.page404': '404',
  'route.errorLog': '错误日志',
  'route.region': '区域管理',
  'route.i18n': '国际化',
  'route.systemSet': '设置',
  'route.manage': '用户管理',
  'navbar.logOut': '退出登录',
  'login.title': '企业号登录',
  'login.logIn': '登录',
  'login.username': '账号',
  'login.password': '密码',
  'login.company_id': '企业号',
  'login.tip_company': '请输入企业号',
  'login.tip_pwd': '请输入密码',
  'login.loginAgree': '登录即表示您已阅读并同意',
  'login.termsService': '隐私协议',
  'login.privacyAgreement': '隐私协议',
  'login.and': '及',
  'login.companyProducts': '深圳优必选科技股份有限公司出品',
  'robot.projectTtitle': '机器人管理系统',
  'robot.manage': '机器人管理',
  'robot.search': '搜索',
  'robot.factorySerialNumber': '工厂序列号/软件序列号',
  'robot.online': '在线',
  'robot.offline': '离线',
  'robot.remainingPower': '剩余电量',
  'robot.cumulativeDisinfectTask': '累计消杀任务',
  'robot.cumulativeDisinfectTime': '累计消杀时间',
  'robot.second': '次',
  'robot.hours': '小时',
  'robot.minutes': '分钟',
  'robot.seconds': '秒',
  'robot.InSitu_disinfect': '原地消杀',
  'robot.explore_disinfect': '自主探索',
  'robot.deploy_disinfect': '现有地图',
  'robot.start': '开始',
  'robot.dock': '上桩',
  'robot.leave_dock': '下桩',
  'robot.locomotion': '控制移动',
  'robot.location': '定位',
  'robot.navigation': '导航',
  'robot.navigation_dock': '回冲导航',
  'robot.navigation_origin': '回到原点',
  'robot.voicebroadcast': '语音播报',
  'robot.countdown': '倒计时',
  'robot.disinfect': '消杀',
  'robot.explore': '自主探索',
  'robot.sleep': '就地等待',
  'robot.charging': '充电中',
  'robot.pause': '暂停',
  'robot.end': '结束',
  'robot.idle': '空闲',
  'robot.in_operation': '运行中',
  'robot.hangup': '挂起',
  'robot.failed': '失败',
  'robot.canceled': '中断',
  'robot.finish': '完成',
  'robot.safeguard': '安全事件暂停',
  'robot.manual': '手动暂停',
  'robot.safeguard_pir': 'pir暂停',
  'robot.safeguard_ai': '摄像头暂停',
  'robot.safeguard_access_control': '门禁牌晃动',
  'robot.access_control': '门禁牌断开',
  'robot.high_temperature': '过温保护',
  'robot.detail': '机器人详情',
  'robot.getLog': '获取日志',
  'robot.onlineStatus': '在线状态',
  'robot.factorySerial': '工厂序列号',
  'robot.softwareSerial': '软件序列号',
  'robot.firstActivationTime': '首次激活时间',
  'robot.lastOnlineTime': '最近上线时间',
  'robot.systemStatus': '系统状态',
  'robot.doorControl': '门禁',
  'robot.doorControl2': '门禁牌号',
  "robot.doorControlnone": '无',
  'robot.doorControl_mac': '门禁牌MAC地址',
  'robot.network': '网络',
  'robot.edition': '版本号',
  'robot.app_edition': 'APP版本号',
  'robot.timedTask': '定时任务',
  'robot.getting': '正在获取中',
  'robot.rosLog': 'ROS日志',
  'robot.navLog': '导航日志',
  'robot.lately': '最近',
  'robot.obtain': '获取',
  'robot.choice': '选择',
  'robot.obtainSucess': '获取成功',
  'robot.clickDownload': '点击下载',
  'robot.viewDetails': '查看明细',
  'robot.robotOfflineNo': '机器人离线，无法操作',
  'robot.resetSure': '确认重置吗',
  'robot.resetLoginPwd': '确认将重置本机器人登录密码为123456',
  'robot.robotEdition': '机器人版本号',
  'robot.resetLoginPwdSuccess': '机器人登录密码已重置为123456',
  'robot.resetLoginPwdTip': '请在平板端重新登录并设置新密码',
  'robot.robotOfflineTip1': '机器人离线，不允许创建定时任务',
  'robot.robotOfflineTip2': '机器人离线，不允许回桩',
  'robot.robotOfflineTip3': '机器人离线，不允许创建任务',
  'robot.robotOfflineTip4': '机器人离线，不允许关机',
  'robot.unpaired': '未绑定',
  'robot.notConnected': '未连接',
  'robot.connected': '已连接',
  'robot.fourday':'四天',
  'robot.fiveday':'五天',
  'robot.sixday':'六天',
  'robot.sevenday':'七天',
  'robot.changeNameTitle':'编辑机器人别名',
  'robot.changeNameSuccess':'修改成功',
  'robot.robotNoExist':'机器人不存在',
  'robot.changeNameFail':'修改失败',
  'robot.delRobotTips':'删除后，该机器人的任务数据将被清空',
  'robot.delRobotTitle':'确认删除该机器人吗',
  'robot.delRobotDone':'机器人已删除',
  'robot.delRobotFail':'机器人不是已离开状态，禁止删除',
  'robot.useOriName': '使用原名',
  'robot.ddisName': 'Wi-Fi名',
  
  'system.operation': '操作',
  'system.set': '系统设置',
  'system.robotManage': '机器人权限管理',
  'system.robotRule1': '允许机器人本体创建、修改或删除房间信息',
  'system.robotRule2': '允许机器人本体更改区域归属',
  'system.setSuccess': '设置成功',
  'system.cancel': '取消',
  'system.determine': '确定',
  'system.companyNoExis': '企业号不存在',
  'system.userNoExis': '用户名不存在',
  'system.passwordNoExis': '账号或密码不正确，错误5次将被锁定10分钟，您已经错误N次',
  
  'settings.title': '系统布局配置',
  'settings.theme': '主题色',
  'settings.showTagsView': '显示 Tags-View',
  'settings.showSidebarLogo': '显示侧边栏 Logo',
  'settings.fixedHeader': '固定 Header',
  'settings.sidebarTextTheme': '侧边栏文字主题色',

  'system.status': '状态',
  'system.createTime': '创建时间',
  'system.search': '查询',
  'system.reset': '重置',
  'system.open': '展开',
  'system.retract': '收起',
  'system.inProgress': '进行中',
  'system.normalCompletion': '正常完成',
  'system.abnormalEnd': '异常结束',
  'system.create': '创建',
  'system.delete': '删除',
  'system.rename': '重命名',
  'system.change': '更改',
  'system.add': '添加',
  'system.createSuccess': '创建成功',
  'system.changeSuccess': '更改成功',
  'system.deleteSuccess': '删除成功',
  'system.tips': '提示',
  'system.noData': '暂无数据',
  'system.startTime': '开始时间',
  'system.endTime': '结束时间',
  'system.total': '全部',
  'system.choice': '请选择',
  'system.noTask': '暂无任务',
  'system.loginTimeout': '登录超时',
  'system.page': '共 XX 条',

  'disinfect.room': '消杀房间',
  'disinfect.pattern': '消杀模式',
  'disinfect.totalTime': '消杀总时长',
  'disinfect.existingMap': '现有地图',
  'disinfect.starting': '进行中',
  'disinfect.detail': '消杀详情',
  'disinfect.export': '导出',
  'disinfect.exportTip': '是否导出当前筛选的所有数据？',
  'disinfect.exportTitle': '导出数据',
  'disinfect.exportFail': '导出失败',

  'region.area': '区域',
  'region.room': '房间',
  'region.noRoom': '您还没创建任何房间',
  'region.createNow': '立即创建',
  'region.noArea': '您还没创建任何区域',
  'region.changeArea': '更改区域名',
  'region.createArea': '创建新区域',
  'region.changeRoom': '更改房间名',
  'region.createRoom': '创建新房间',
  'region.enterArea': '请输入区域名',
  'region.enterRoom': '请输入房间名',
  'region.modalTip1': '删除房间将导致该房间关联的所有定时任务和地图被同步删除，请谨慎操作',
  'region.modalTip2': '删除区域前请先删除该区域内的全部房间',
	"region.modalTip3": "确认删除该区域吗？",
  'region.delArea': '删除区域',
  'region.delRoom': '删除房间',
  'region.noMapTip': '您还没上传任何地图',
  'region.sourceRobot': '来源机器人',
  'region.UpdateTime': '上传时间',
  'region.reNewMap': '更改地图名',
  'region.enterNewMapName': '输入新的地图名',
  'region.enterName': '请输入名称',
  'region.confirmDelMap': '确认删除该地图吗？',
  'region.delMap': '删除地图',
  'region.reNameMapSuccess': '重命名成功',
  'region.mapNameconflict': '地图名称冲突',
  'region.delMapNoRoom': '房间不存在',
  
  'dataReport.robotNum': '机器人数量',
  'dataReport.cumulativeTaskTimes': '累计任务次数',
  'dataReport.disinfectDuration': '累计消杀时长',
  'dataReport.platform': '台',
  'dataReport.title1': '区域任务次数&消杀时长',
  'dataReport.title2': '房间任务次数&消杀时长',
  'dataReport.title3': '机器人使用率',
  'dataReport.today': '今日',
  'dataReport.lastWeek': '最近一周',
  'dataReport.lastMonth': '最近一月',
  'dataReport.lastYear': '最近一年',
  'dataReport.taskCount': '任务次数',
  'dataReport.taskTime': '任务时长',
  'dataReport.disinfectTime': '消杀时长',
  'dataReport.taskCountTotal': '任务次数总计',
  'dataReport.rate': '使用率',
  'dataReport.intervalTip': '最长只能选择31天',

  'task.yjhz': '一键回桩',
  'minbox.aqsjzt': '安全设置暂停',
  'minbox.sdjieshu': '手动结束',
  'minbox.mjtz': '门禁停止',
  'minbox.stchufa': '底盘解锁',
  'minbox.mjdl':'门禁断连',
  'minbox.pirjs': 'PIR结束',
  'minbox.sxtjs': '摄像头结束',
  'minbox.mjjs': '门禁结束',
  'minbox.dici': '触发地磁条',
  'minbox.fangzt': '碰撞防撞条',
  'minbox.dlguoji': '电机过流 ',
  'minbox.xyjc': '悬崖检测',
  'minbox.sbgz': '设备故障',
  'minbox.daohangshibai': '导航失败',
  'minbox.dingweishibai': '定位失败',
  'minbox.jiantushibai': '建图失败',
  'minbox.xiazhuangsb': '下桩失败',
  'minbox.shangzhuangsb': '上桩失败',
  'minbox.cgqyc': '传感器异常',
  'minbox.dhcscw': '导航参数错误',
  'minbox.dhfwqcw': '导航服务器错误',
  'minbox.mbdbkd': '目标点不可达',
  'minbox.dhmkcshsb': '导航模块初始化失败',
  'minbox.zbdcdz': '找不到充电桩',
  'minbox.yczd': '异常中断',
  'minbox.qita': '其他',
  'creted.renwu': '创建任务',
  'creted.cjdsrw': '创建定时任务',
  'creted.bjdsrw': '编辑定时任务',
  'creted.zztansuo': '自主探索',
  'creted.ydxs': '原地消杀',
  'creted.xssj': '消杀时间',
  'creted.ddxssj': '单点消杀时长',
  'creted.aqjc': '安全检测',
  'creted.mjp': '门禁牌',
  'creted.bdzthf': '被动暂停后自动结束时间',
  'creted.cj': '创建',
  'creted.cjcgzztz': '创建成功，正在跳转',
  'creted.mjpwljcjsb': '门禁牌未连接，创建失败',
  'creted.openuv': '导航中是否开启UV灯',
  'creted.openuvwarning': '导航中PIR不可用，请确保任务中无人靠近',
  'creted.openuvOn': '开启',
  'creted.openuvOff': '关闭',
  'creted.dqyjxrw': '当前有进行任务，创建失败',
  'creted.xtlbcwcjsb': '系统内部错误，创建失败',
  'creted.sbgzcjsb': '设备故障，创建失败',
  'creted.jqjtqbcan': '机器人急停，请拔出急停按钮',
  'creted.dcyccjsb': '地磁异常，创建失败',
  'creted.fztyccjsb': '防撞条异常，创建失败',
  'creted.dpdjglcjsb': '底盘电机过流或温度过高,创建失败',
  'creted.zdyccjsb': '诊断异常，创建失败',
  'creted.zczcjsb': '桩充中，创建失败',
  'creted.xczcjsb': '线充中，创建失败',
  'creted.dqdtbczwfcj': '当前地图不存在，无法创建',
  'creted.dhmkyxzcjsb': '导航模块运行中，创建失败',
  'creted.cgqyccjsb': '传感器异常，创建失败',
  'creted.jqrwdggcjsb': '机器人温度过高，创建失败',
  'creted.jqrdlxy': '机器人电量小于10%，无法创建',
  'creted.rwlx': '任务类型',
  'creted.dcrw': '单次任务',
  'creted.cfrw': '重复任务',
  'creted.xzrq': '选择日期',
  'creted.cfgh': '重复规划',
  'creted.qxzcfsj': '请选择重复时间',
  'creted.xzsj': '选择时间',
  'creted.cfsj': '重复时间',
  'creted.zhouri': '周日',
  'creted.zhouyi': '周一',
  'creted.zhouer': '周二',
  'creted.zhousan': '周三',
  'creted.zhousi': '周四',
  'creted.zhouwu': '周五',
  'creted.zhouliu': '周六',
  'creted.bcxg': '保存修改',
  'disinfect.xsrwxq':'消杀任务详情',
  'disinfect.jxrw':'继续任务',
  'disinfect.ztrw':'暂停任务',
  'disinfect.jsrw':'结束任务',
  'disinfect.rwzsc': '任务总时长',
  'disinfect.xsditu': '消杀地图',
  'disinfect.gqyzwdt':'暂无地图',
  'disinfect.rwjl': '任务记录',
  'disinfect.rwks':'任务开始',
  'disinfect.tansuo':'探索',
  'disinfect.huidaocdz':'回到充电桩',
  'disinfect.wfddxsd':'无法到达消杀点',
  'disinfect.wfdddhd':'无法到达导航点',
  'disinfect.jqrzzcd':'机器人正在充电',
  'disinfect.xszy': '消杀',
  'disinfect.dddhd':'到达导航点',
  'disinfect.rwjs':'任务结束',
  'disinfect.rwwc':'任务完成',
  'disinfect.xgdsrwcgqsh':'修改定时任务成功，请稍后',
  'disinfect.dqmyfj':'当前没有房间',
  'disinfect.aqsj':'安全事件',
  'disinfect.sdzt':'手动暂停',
  'disinfect.PIRztcs':'PIR暂停超时',
  'disinfect.pirzt':'PIR暂停',
  'disinfect.mjpztcs':'门禁牌暂停超时',
  'disinfect.mjphd':'门禁牌晃动',
  'disinfect.mjpdlcs':'门禁牌断连超时',
  'disinfect.mjpdl':'门禁牌断连',
  'disinfect.wdbhztrw':'温度保护暂停任务',
  'turn.guanji':'一键关机',
  'password.word':'重置登录密码',
  'disinfect.qdsj':'启动时间',
  'disinfect.tjdsrw':'添加定时任务',
  'disinfect.shanchu':'删除',
  'disinfect.sccdsrw':'删除此定时任务',
  'disinfect.qrsccdsrw':'确认删除定时任务吗',
  'disinfect.jqrlxbyxcz':'机器人离线，不允许操作',
  'disinfect.zzsz':'正在上桩',
  'disinfect.qxsz':'取消上桩',
  'disinfect.gb':'关闭',
  'disinfect.szcg':'上桩成功',
  'disinfect.dhqwcdz':'导航前往充电桩',
  'minbox.bentijt':'本体急停',
  'disinfect.jjszzjxrw':'确认结束任务吗?',
  'disinfect.qdjsrw':'确定结束',
  'disinfect.jqrbsydqqyh':'机器人已不属于本企业，无法操作',
  'disinfect.yijlk':'已离开',
  'disinfect.jqrgj':'机器人关机',
  'disinfect.qdhjgj':'确定后机器人将关机',
  'dsrw.rwsjct':'与已有定时任务时间冲突，请选择其他时间',
  'dsrw.rwjgq':'任务时间已过期，请选择其他时间',
  'dsrw.cjsb':'创建失败',
  'disinfect.cpfzt':'触碰防撞条',
  'robotInfo.ljrwcs':'累计任务次数',
  'robotInfo.ljxssc':'累计消杀时长', 
  'robotInfo.ckxsrw':'查看消杀任务',
  'robotInfo.xsrwtj':'消杀任务统计',
  'robotInfo.zjlxsj':'最近离线时间',
  'robotInfo.hardwareStatus':'硬件状态',
  'robotInfo.noAbnormality':'无异常',
  'robotInfo.deviceError':'设备异常',
  'robotInfo.sensorError':'传感器异常',
  'robotInfo.systemError':'系统异常',
  'robotInfo.fault':'故障项',
  'robotInfo.nomal':'正常项',
  'robotInfo.system':'系统类',
  'robotInfo.device':'设备类',
  'robotInfo.sensor':'传感器类',
  'disinfect.rwzwfsc':'机器人正在进行任务，无法删除',

  'agreement.text1':'ADIBOT净巡士™紫外线消毒机器人隐私协议',
  'agreement.text2':'本协议将帮助您了解以下内容：',
  'agreement.text3':'我们如何收集和使用ADIBOT净巡士™紫外线消毒机器人相关信息',
  'agreement.text3-1':'ADIBOT净巡士™紫外线消毒机器人相关信息',
  'agreement.text4':'本协议如何更新',
  'agreement.text5':'如何联系我们',
  'agreement.text6':'深圳市优必选科技股份有限公司（注册地址/常用办公地址：深圳市南山区学苑大道1001号南山智园C1栋16、22楼，以下简称“优必选科技”或“我们”）。',
  'agreement.text7':'请您在使用',
  'agreement.text7-1':'ADIBOT净巡士™紫外线消毒机器人',
  'agreement.text7-2':'前，仔细阅读并了解本',
  'agreement.text7-3':'ADIBOT净巡士™紫外线消毒机器人',
  'agreement.text7-4':'隐私协议》。',
  'agreement.text8':'我们将尽力采用通俗易懂的文字表述向您进行说明和告知，并就本协议中可能与您的合法利益存在重大关系的条款，采用字体加粗的方式以提请您的注意。',
  'agreement.text8-1':'我们强烈建议您在首次使用我们的服务前，仔细阅读并完全理解本协议中的全部内容，并自主进行适当的选择。如果您不同意本协议的内容，我们将无法为您提供完整的产品和服务，同时您也需要立即停止使用',
  'agreement.text8-2':'ADIBOT净巡士™紫外线消毒机器人及服务',
  'agreement.text8-3':'。如您开始使用我们的产品或服务，则视为您已经充分、完全地阅读并理解了本协议中的全部内容，并且同意我们按照本协议的约定收集、使用、存储您所购买的ADIBOT的信息，以便您享受优质、便捷的服务。',
  'agreement.text9':'本协议中涉及的专用术语和关键词有：',
  'agreement.text10':'1. 机器人的设备状态信息：',
  'agreement.text10-1':' 机器人各个元器件的状态信息；',
  'agreement.text11':'2. 机器人的版本信息：您所使用的机器人本体和App的版本信息、后台管理系统版本信息；',
  'agreement.text12':'3. 机器人的任务信息：即您',
  'agreement.text12-1':'创建的消杀任务的',
	'agreement.text12-2':'信息，包括',
	'agreement.text12-3':'任务创建的时间、任务总时长、消杀总时长以及执行任务过程中各个节点的状态信息和任务中您所',
	'agreement.text12-4':'使用的地图信息；',
  'agreement.text13':'4. 日志信息：一段时间内的机器人设备信息的集合。',
  'agreement.text14':'一、我们如何收集和使用您在使用ADIBOT净巡士™紫外线消毒机器人过程中产生的信息',
  'agreement.text15':'为了维护所提供产品或服务的安全稳定运行，例如发现、分析产品或服务的故障，当',
  'agreement.text15-1':'您使用我们提供的公有云服务时，我们系统可能通过SDK或其他方式自动采集以下信息：',
  'agreement.text16':'（1）机器人设备信息：包括机器人的设备状态信息和机器人的版本信息',
  'agreement.text17':'（2）机器人的任务信息',
  'agreement.text18':'（3）日志信息',
  'agreement.text19':'（二）您设备信息使用的规则',
  'agreement.text20':'1、我们会根据本隐私协议，约定我们的产品与服务功能对所收集的信息进行使用；',
  'agreement.text21':'2、当您选择我们的公有云服务时，您机器人的相关信息均会上传至我们的服务器，以保证您可以通过云端操控机器人或查看机器人的设备信息、任务信息和日志信息；',
  'agreement.text22':'3、当您的机器人出现故障时，我们在获得您的授权之后，会根据设备信息、任务信息和日志信息，分析并排除故障。',
  'agreement.text23':'二、本协议如何更新',
  'agreement.text24':'我们的隐私协议可能变更。',
  'agreement.text25':'未经您明确同意，我们不会削减您依据当前生效的本协议所应享受的权利。当本协议发生重大变化时，我们将通知您，构成重大变化的情况包括但不限于：',
  'agreement.text26':'1. 我们提供的服务范围、模式等发生重大变化，如收集、使用信息的目的、处理信息的类型、信息的使用方式、接收数据的第三方身份等发生改变；',
  'agreement.text27':'2. 我们的经营主体、组织架构、所有权结构等方面发生重大变化，如业务调整、兼并收购重组等引起的所有者变更；',
  'agreement.text28':'3. 您参与信息处理方面的权利及其行使方式发生重大变化；',
  'agreement.text29':'4. 信息共享、转让或公开披露的方式、主要对象等发生变化；',
  'agreement.text30':'5. 信息风险评估报告表明存在高风险时；',
  'agreement.text31':'6. 我们负责信息安全的责任部门及其联系方式、投诉渠道发生变化；',
  'agreement.text32':'7. 其他可能对您的信息权益产生重大影响的变化。',
  'agreement.text33':'为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本协议更新生效后继续使用机器人及服务，即表示您已充分阅读、理解并接受更新后的协议并愿意受更新后的协议约束。',
  'agreement.text34':'每当隐私协议更新时，我们会通过弹窗提醒的方式通知您，您可以选择查看或忽略。',
  'agreement.text35':'您可以在本App通过“设置→关于→隐私协议”或在ADIS系统通过“设置→隐私协议”中查看本协议。我们鼓励您在每次使用App时都查阅我们的隐私协议。',
  'agreement.text36':'三、如何联系我们',
  'agreement.text37':'如果您对本隐私协议有任何问题、意见或建议，请通过以下联系方式与我们联系：',
  'agreement.text38':'地址：深圳市南山区学苑大道1001号南山智园C1栋16、22楼',
  'disinfect.days':'每天',
  'disinfect.tbzt': '同步状态',
  'disinfect.tbztcg': '同步状态成功',
  'disinfect.xssjbwl': '消杀时间不可为0',
  'disinfect.yjchongqi': '一键重启',
  'disinfect.xiancz': '线充中',
  'disinfect.xiancycm': '线充已充满',
  'disinfect.zczhong': '桩充中',
  'disinfect.zczhongycm': '桩充已充满',
  'disinfect.jtzbyxccz': '急停中，不允许此操作',
  'disinfect.cdzbyxccz': '充电中，不允许此操作',
  'disinfect.qrcqjqzjsrw': '确认重启将强制结束任务',
  'disinfect.jqrzzjxrw': '机器人正在进行任务',
  'disinfect.qrhjqrjcq': '确认后机器人将重启',
  'disinfect.jqrcq': '机器人重启',
  'disinfect.qrgjjqzjsrw': '确认关机将强制结束任务',
  'disinfect.rwzbyxczz': '任务中，不允许此操作',
  'disinfect.zdszwgrw': '最多可设置20个定时任务',
  'disinfect.fjmycz': '房间名已存在,请重新命名',
  'disinfect.qymycz': '区域名已存在,请重新命名',
  'disinfect.czsbl': '操作失败',
  'disinfect.chengtisb': '乘梯失败',

  'robotInfo.log.startWord': '从',
  'robotInfo.log.endWord': '到',
  'robotInfo.log.chooseTime': '选择日期',
  'robotInfo.log.hasNoLog': '该时间日志已过期或不存在，请重新选择',
  'robotInfo.log.getFail': '获取失败',
  'robotInfo.log.packing': '正在打包中',
  'robotInfo.log.pleaseselect': '请选择7天范围内的日志，否则文件可能过大'
}
