
<template>
  <div class="navbar">
    <Hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggle-click="toggleSideBar"
    />
    <!-- <BreadCrumb
      id="breadcrumb-container"
      class="breadcrumb-container"
    /> -->
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->
        <Screenfull class="right-menu-item hover-effect"  v-show="false"/>
        <!-- <el-tooltip
          :content="t('navbar.size')"
          effect="dark"
          placement="bottom"
        >
          <SizeSelect class="right-menu-item hover-effect" />
        </el-tooltip> -->
        <!-- <LangSelect class="right-menu-item hover-effect" /> -->
      </template>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img
            :src="user.avatar + '?imageView2/1/w/80/h/80'"
            class="user-avatar"
          >
          <span>{{ user.name==''?user.email:user.name }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu style="padding: 4px;">
            <div class="username" >
              <div><i class="el-icon-office-building"></i>{{ user.company }}</div>
              <div><i class="el-icon-user"></i>{{ user.name==''?user.email:user.name }}</div>
            </div>
            <div class="divider"></div>
            <router-link to="/set/systemSet">
              <el-dropdown-item>
                {{ t("route.systemSet") }}
              </el-dropdown-item>
            </router-link>
            <el-dropdown-item
              divided
              @click="logout"
            >
              <span style="display:block;">
                {{ t("navbar.logOut") }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
// import BreadCrumb from '@/components/bread-crumb/Index.vue'
import Hamburger from '@/components/hamburger/Index.vue'
import Screenfull from '@/components/screenfull/Index.vue'
import LangSelect from '@/components/lang_select/Index.vue'
// import SizeSelect from '@/components/size_select/Index.vue'

import { computed, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { useI18n } from 'vue-i18n'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {
    // BreadCrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    // SizeSelect
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const sidebar = computed(() => {
      return store.state.app.sidebar
    })
    const device = computed(() => {
      return store.state.app.device.toString()
    })
    const user = computed(() => {
      console.log('store.state', store.state)
      return store.state.user
    })
    const state = reactive({
      toggleSideBar: () => {
        store.dispatch(AppActionTypes.ACTION_TOGGLE_SIDEBAR, false)
      },
      logout: () => {
        useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT)
        router.push(`/login?redirect=${route.fullPath}`).catch(err => {
          console.warn(err)
        })
      }
    })
    return {
      sidebar,
      device,
      user,
      ...toRefs(state),
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.username{
  line-height: 30px;
  font-size: 14px;
  color: #606266;
  padding: 10px 4px 0;
  i{
    margin: 0 10px 0 0;
  }
}
.divider{
  background-color: #DCDFE6;
  height: 1px;
  width: 100%;
  margin: 6px 0;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      .avatar-wrapper {
        position: relative;
        // bottom: 16px;
        margin-right: 20px;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 40px;
          display: none;
        }
        span {
          // display: inline-block;
          height: 40px;
          line-height: 40px;
          margin-left: 10px;
          cursor: pointer;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
