

export enum UserMutationTypes {
  SET_TOKEN = 'SET_TOKEN',
  SET_NAME = 'SET_NAME',
  SET_COMPANY = 'SET_COMPANY',
  SET_AVATAR = 'SET_AVATAR',
  SET_INTRODUCTION = 'SET_INTRODUCTION',
  SET_ROLES = 'SET_ROLES',
  SET_EMAIL = 'SET_EMAIL',
  SET_Authority = 'SET_Authority',
}
