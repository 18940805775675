/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-10-22 10:39:52
 * @LastEditors: zhongbin.chen
 * @LastEditTime: 2021-11-20 14:43:14
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const disinfectRouter: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'disinfect',
        component: () => import('@/views/Robot/Disinfect.vue'),
        name: 'disinfect',
        meta: {
          title: 'disinfect',
          icon: 'disinfectNav'
        }
      },
    ]
  }
]

export default disinfectRouter
