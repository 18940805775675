export default {
  "route.dashboard": "Panel",
  "route.robot": "Robot",
  "route.disinfect": "Tareas",
  "route.errorPages": "Pagina de error",
  "route.page401": "401",
  "route.page404": "404",
  "route.errorLog": "Registro de errores",
  "route.region": "Área",
  "route.i18n": "Globalización",
  "route.systemSet": "Configuración",
  "route.manage": "Usuario",
  "navbar.logOut": "Cerrar sesión",
  "login.title": "Inicio de sesión con cuenta empresarial",
  "login.logIn": "Inicio de sesión",
  "login.username": "Nombre de usuario",
  "login.password": "Contraseña",
  "login.company_id": "Cuenta empresarial",
  "login.tip_company": "Ingrese la cuenta de empresa",
  "login.tip_pwd": "Ingrese la contraseña",
  "login.loginAgree": "Iniciar sesión atesta que ha leído y acepta los",
  "login.termsService": "Terminos del servicio",
  "login.privacyAgreement": "Acuerdo de privacidad",
  "login.and": "y",
  "login.companyProducts": "Producido por UBTECH Robotics Corp.",
  "robot.projectTtitle": "Sistema de Gestión de Robots",
  "robot.manage": "Gestión de Robots",
  "robot.search": "Buscar",
  "robot.factorySerialNumber": "Número de serie de fábrica/número de serie de software",
  "robot.online": "En línea",
  "robot.offline": "Sin conexión",
  "robot.remainingPower": "Batería restante",
  "robot.cumulativeDisinfectTask": "Tareas acumuladas",
  "robot.cumulativeDisinfectTime": "Duración acumulada",
  "robot.second": "tareas",
  "robot.hours": "h",
  "robot.minutes": "min",
  "robot.seconds": "s",
  "robot.InSitu_disinfect": "Desinfección en el lugar",
  "robot.explore_disinfect": "Desinfección auto-explorativa",
  "robot.deploy_disinfect": "Desinfección con mapa",
  "robot.start": "Inicio",
  "robot.dock": "Moviéndose hacia la base de carga",
  "robot.leave_dock": "Saliendo de la base de carga",
  "robot.locomotion": "Control de movimiento",
  "robot.location": "Posicionamiento",
  "robot.navigation": "Navegando",
  "robot.navigation_dock": "Navegando de vuelta a la base de carga",
  "robot.navigation_origin": "Regresando a la posición inicial",
  "robot.voicebroadcast": "Transmisión de voz",
  "robot.countdown": "Cuenta regresiva",
  "robot.disinfect": "Desinfección",
  "robot.explore": "Auto-exploración",
  "robot.sleep": "Esperar en su lugar",
  "robot.charging": "Cargando",
  "robot.pause": "Pausa",
  "robot.end": "Fin",
  "robot.idle": "Inactivo",
  "robot.in_operation": "Ejecutando",
  "robot.hangup": "Atascado",
  "robot.failed": "Fallido",
  "robot.canceled": "Terminado",
  "robot.finish": "Completado",
  "robot.safeguard": "Pausa por incidente de seguridad",
  "robot.manual": "Aplicación en pausa",
  "robot.safeguard_pir": "PIR en pausa",
  "robot.safeguard_ai": "Cámara en pausa",
  "robot.safeguard_access_control": "letrero de seguridad ha sido movido",
  "robot.access_control": "letrero de seguridad desconectado",
  "robot.high_temperature": "Protection por alta temperatura",
  "robot.detail": "Detalles del robot",
  "robot.getLog": "Obtener registros",
  "robot.onlineStatus": "Estado en línea",
  "robot.factorySerial": "Numero de serie de fabrica",
  "robot.softwareSerial": "Numero de serie del software",
  "robot.firstActivationTime": "Activacion por primera vez",
  "robot.lastOnlineTime": "Última vez en línea",
  "robot.systemStatus": "Estado del sistema",
  "robot.doorControl": "letrero de seguridad",
  "robot.doorControl2": "letrero de seguridad",
  "robot.doorControlnone": "Acceso estándar",
  "robot.doorControl_mac": "MAC del letrero de seguridad",
  "robot.network": "Red",
  "robot.edition": "Número de la versión",
  "robot.app_edition": "Número de la versión de la aplicación",
  "robot.timedTask": "Tarea cronometrada",
  "robot.getting": "Adquiriendo",
  "robot.rosLog": "registros ROS",
  "robot.navLog": "Registro de navegación",
  "robot.lately": "Reciente",
  "robot.obtain": "Adquirir",
  "robot.choice": "Seleccionar",
  "robot.obtainSucess": "Adquirido con éxito",
  "robot.clickDownload": "Descargar",
  "robot.viewDetails": "Ver detalles",
  "robot.robotOfflineNo": "El robot está sin conexión",
  "robot.resetSure": "¿Estás seguro de resetear?",
  "robot.resetLoginPwd": "Confirmar que la contraseña de inicio de sesión del robot se restablecerá a 123456",
  "robot.robotEdition": "Número de versión del robot",
  "robot.resetLoginPwdSuccess": "La contraseña de inicio de sesión del robot se ha restablecido a 123456",
  "robot.resetLoginPwdTip": "Por favor, inicie sesión nuevamente en la tableta y establezca una nueva contraseña",
  "robot.robotOfflineTip1": "El robot está desconectado y no puede crear tareas programadas",
  "robot.robotOfflineTip2": "El robot está desconectado y no puede regresarse a la estacion de carga",
  "robot.robotOfflineTip3": "El robot está desconectado y no se permite la creación de tareas",
  "robot.robotOfflineTip4": "El robot está desconectado y no se puede apagar",
  "robot.unpaired": "No emparejado",
  "robot.notConnected": "No conectado",
  "robot.connected": "Conectado",
  "robot.fourday": "Cuatro días",
  "robot.fiveday": "Cinco días",
  "robot.sixday": "Seis días",
  "robot.sevenday": "Siete días",
  "robot.changeNameTitle": "Editar nombre del robot",
  "robot.changeNameSuccess": "Modificado con éxito",
  "robot.robotNoExist": "El robot no existe",
  "robot.changeNameFail": "Error al editar",
  "robot.delRobotTips": "Después de eliminar, los datos de las tareas del robot se borrarán",
  "robot.delRobotTitle": "¿Estás seguro de que quieres eliminar este robot?",
  "robot.delRobotDone": "Robot eliminado",
  "robot.delRobotFail": "El robot no está en estado izquierdo, prohibido eliminar",
  "robot.useOriName": "Usar nombre original",
  "robot.ddisName": "Nombre de Wi-Fi",
  "system.operation": "Operación",
  "system.set": "Configuración del sistema",
  "system.robotManage": "Gestión de permisos del robot",
  "system.robotRule1": "Permitir al robot crear, modificar o eliminar información de habitaciones",
  "system.robotRule2": "Permitir al robot cambiar la atribución de áreas",
  "system.setSuccess": "Establecido exitosamente",
  "system.cancel": "Cancelar",
  "system.determine": "Confirmar",
  "system.companyNoExis": "El número de empresa no existe",
  "system.userNoExis": "El usuario no existe",
  "system.passwordNoExis": "La cuenta o la contraseña son incorrectas, si comete 5 errores será bloqueado por 10 minutos. Ha cometido errores N veces.",
  "settings.title": "Configuración del diseño del sistema",
  "settings.theme": "Color del tema",
  "settings.showTagsView": "Mostrar vista de etiquetas",
  "settings.showSidebarLogo": "Mostrar logotipo en la barra lateral",
  "settings.fixedHeader": "Encabezado fijo",
  "settings.sidebarTextTheme": "Color del tema del texto de la barra lateral",
  "system.status": "Estado",
  "system.createTime": "Hora de inicio",
  "system.search": "Buscar",
  "system.reset": "Restablecer",
  "system.open": "Mostrar",
  "system.retract": "Ocultar",
  "system.inProgress": "En progreso",
  "system.normalCompletion": "Completado",
  "system.abnormalEnd": "Terminación anormal",
  "system.create": "Crear",
  "system.delete": "Eliminar",
  "system.rename": "Renombrar",
  "system.change": "Cambiar",
  "system.add": "Agregar",
  "system.createSuccess": "Creado exitosamente",
  "system.changeSuccess": "Editado exitosamente",
  "system.deleteSuccess": "Eliminado exitosamente",
  "system.tips": "Notificación",
  "system.noData": "Sin datos relevantes",
  "system.startTime": "Hora de inicio",
  "system.endTime": "Hora de finalización",
  "system.total": "Todo",
  "system.choice": "Por favor seleccione",
  "system.noTask": "No hay tarea",
  "system.loginTimeout": "Inicio de sesión agotado",
  "system.page": "Total XX",
  "disinfect.room": "Habitación",
  "disinfect.pattern": "Modo",
  "disinfect.totalTime": "Duración de la desinfección",
  "disinfect.existingMap": "Usar mapa",
  "disinfect.starting": "En progreso",
  "disinfect.detail": "Detalles de la tarea",
  "disinfect.export": "Exportar",
  "disinfect.exportTip": "¿Exportar todos los datos actualmente filtrados?",
  "disinfect.exportTitle": "Exportar",
  "disinfect.exportFail": "Error al exportar",
  "region.area": "Área",
  "region.room": "Habitación",
  "region.noRoom": "No has creado ninguna habitación",
  "region.createNow": "Crear ahora",
  "region.noArea": "No ha creado ninguna área",
  "region.changeArea": "Cambiar nombre del área",
  "region.createArea": "Crear nueva área",
  "region.changeRoom": "Cambiar nombre de la habitación",
  "region.createRoom": "Crear nueva habitación",
  "region.enterArea": "Por favor, ingrese el nombre del área",
  "region.enterRoom": "Por favor, ingrese el nombre de la habitación",
  "region.modalTip1": "Las tareas cronometradas y los mapas relacionados con esta habitación se eliminarán al eliminar esta habitación. ¿Estás seguro de que quiere eliminar esta habitación?",
  "region.modalTip2": "Por favor, elimine todas las habitaciones del área antes de eliminar el área",
  "region.modalTip3": "¿Estás seguro de eliminar esta área?",
  "region.delArea": "Eliminar área",
  "region.delRoom": "Eliminar habitación",
  "region.noMapTip": "No se ha subido ningún mapa",
  "region.sourceRobot": "robot de origen",
  "region.UpdateTime": "hora que fue subido",
  "region.reNewMap": "Cambiar nombre del mapa",
  "region.enterNewMapName": "Ingrese nuevo nombre del mapa",
  "region.enterName": "Por favor, ingrese un nombre",
  "region.confirmDelMap": "¿Está seguro de que desea eliminar este mapa?",
  "region.delMap": "Eliminar mapa",
  "region.reNameMapSuccess": "Nombre ha sido modificado con éxito",
  "region.mapNameconflict": "Un mapa con el mismo nombre ya existe",
  "region.delMapNoRoom": "La habitación no existe",
  "dataReport.robotNum": "Número de robots",
  "dataReport.cumulativeTaskTimes": "Tareas acumuladas",
  "dataReport.disinfectDuration": "Duración acumulada",
  "dataReport.platform": "",
  "dataReport.title1": "Número de tareas y duración de desinfección en áreas",
  "dataReport.title2": "Número de tareas/duración de desinfección en habitaciones",
  "dataReport.title3": "Tasa de uso del robot",
  "dataReport.today": "Hoy",
  "dataReport.lastWeek": "Semana pasada",
  "dataReport.lastMonth": "Mes pasado",
  "dataReport.lastYear": "Año pasado",
  "dataReport.taskCount": "Número de tareas",
  "dataReport.taskTime": "Duración de la tarea",
  "dataReport.disinfectTime": "Duración de la desinfección",
  "dataReport.taskCountTotal": "Número total de tareas",
  "dataReport.rate": "Tasa de utilización",
  "dataReport.intervalTip": "Solo puede seleccionar un máximo de 31 días",
  "task.yjhz": "Volver a la base de carga",
  "minbox.aqsjzt": "Un incidente de seguridad pausó la tarea",
  "minbox.sdjieshu": "Terminado manualmente",
  "minbox.mjtz": "El botón de emergencia del letrero de seguridad terminó la tarea",
  "minbox.stchufa": "Chasis desbloqueado",
  "minbox.mjdl": "Letrero de seguridad desconectado",
  "minbox.pirjs": "Pausa del sensor PIR agotada",
  "minbox.sxtjs": "Pausa de la camara agotada",
  "minbox.mjjs": "Pausa del letrero de seguridad agotada",
  "minbox.dici": "Banda geomagnética ha sido activada",
  "minbox.fangzt": "Barra anticolisión ha sido activada",
  "minbox.dlguoji": "Sobrecorriente del motor",
  "minbox.xyjc": "Detección de acantilados",
  "minbox.sbgz": "Fallo del dispositivo",
  "minbox.daohangshibai": "Navegación fallida",
  "minbox.dingweishibai": "Fallo de posición",
  "minbox.jiantushibai": "Fallo al construir el mapa",
  "minbox.xiazhuangsb": "Fallo al dejar la base de carga",
  "minbox.shangzhuangsb": "Fallo al conectar a la base de carga",
  "minbox.cgqyc": "Sensores anormales",
  "minbox.dhcscw": "Error en parámetros de navegación",
  "minbox.dhfwqcw": "Error del servidor de navegación",
  "minbox.mbdbkd": "Ubicación inalcanzable",
  "minbox.dhmkcshsb": "Error en el módulo de navegación",
  "minbox.zbdcdz": "No se puede encontrar la base de carga",
  "minbox.yczd": "Interrupción anormal",
  "minbox.qita": "Otros",
  "creted.renwu": "Crear tarea",
  "creted.cjdsrw": "Crear tareas cronometradas",
  "creted.bjdsrw": "Editar tareas cronometradas",
  "creted.zztansuo": "Desinfección auto-explorativa",
  "creted.ydxs": "Desinfección en el lugar",
  "creted.xssj": "Duración de la desinfección",
  "creted.ddxssj": "Duración de la desinfección de cada punto",
  "creted.aqjc": "Ajustes de seguridad",
  "creted.mjp": "letrero de seguridad",
  "creted.bdzthf": "Finalizar tarea automáticamente después de que la pausa dure más de",
  "creted.cj": "Crear",
  "creted.cjcgzztz": "Creado con éxito, redirigiendo",
  "creted.mjpwljcjsb": "letrero de seguridad no está conectada. Falló al crear.",
  "creted.openuv": "Encender Luz UV mientras navega",
  "creted.openuvwarning": "El sensor PIR no estára disponible durante la navegación, asegúrese de que nadie se acerque durante la tarea",
  "creted.openuvOn": "Encender",
  "creted.openuvOff": "Apagar",
  "creted.dqyjxrw": "Tarea en progreso. Error al crear.",
  "creted.xtlbcwcjsb": "Error interno del sistema. Error al crear.",
  "creted.sbgzcjsb": "Fallo del dispositivo. Error al crear.",
  "creted.jqjtqbcan": "El robot se detuvo en emergencia. Por favor, hale el botón de parada de emergencia para descartar la situación.",
  "creted.dcyccjsb": "Geomagnético es anormal. No se pudo crear.",
  "creted.fztyccjsb": "La barra anti-colisión es anormal. No se pudo crear.",
  "creted.dpdjglcjsb": "Sobrecorriente/Temperatura del motor es demasiado alta. No se pudo crear.",
  "creted.zdyccjsb": "Diagnóstico es anormal. No se pudo crear.",
  "creted.zczcjsb": "El robot está en la base de carga. No se pudo crear.",
  "creted.xczcjsb": "Cable de carga conectado al robot. No se pudo crear.",
  "creted.dqdtbczwfcj": "El mapa actual no existe. No se pudo crear.",
  "creted.dhmkyxzcjsb": "El módulo de navegación está en funcionamiento. No se pudo crear.",
  "creted.cgqyccjsb": "Sensores anormales. No se pudo crear.",
  "creted.jqrwdggcjsb": "La temperatura del robot es demasiado alta. No se pudo crear.",
  "creted.jqrdlxy": "La bateria del robot es inferior al 10%, no se puede crear.",
  "creted.rwlx": "Tipo de tarea",
  "creted.dcrw": "Tarea única",
  "creted.cfrw": "Repetir Tarea",
  "creted.xzrq": "Seleccionar fecha",
  "creted.cfgh": "Seleccionar día(s)",
  "creted.qxzcfsj": "Por favor seleccione",
  "creted.xzsj": "Seleccionar hora",
  "creted.cfsj": "Seleccionar hora",
  "creted.zhouri": "Dom.",
  "creted.zhouyi": "Lun.",
  "creted.zhouer": "Mar.",
  "creted.zhousan": "Mié.",
  "creted.zhousi": "Jue.",
  "creted.zhouwu": "Vie.",
  "creted.zhouliu": "Sáb.",
  "creted.bcxg": "Guardar cambios",
  "disinfect.xsrwxq": "Detalles de la tarea",
  "disinfect.jxrw": "Restaurar tarea",
  "disinfect.ztrw": "Suspender tarea",
  "disinfect.jsrw": "Terminar tarea",
  "disinfect.rwzsc": "Duración de la tarea",
  "disinfect.xsditu": "Mapa de desinfección",
  "disinfect.gqyzwdt": "Sin mapa",
  "disinfect.rwjl": "Registros de tarea",
  "disinfect.rwks": "Tarea iniciada",
  "disinfect.tansuo": "Explorando",
  "disinfect.huidaocdz": "De regreso a la base de carga",
  "disinfect.wfddxsd": "No puede alcanzar el punto de desinfección",
  "disinfect.wfdddhd": "No puede alcanzar el punto de navegación",
  "disinfect.jqrzzcd": "El robot está cargando",
  "disinfect.xszy": "Desinfección",
  "disinfect.dddhd": "Alcanzar el punto de navegación",
  "disinfect.rwjs": "Tarea terminada",
  "disinfect.rwwc": "Tarea completada",
  "disinfect.xgdsrwcgqsh": "Editado con éxito",
  "disinfect.dqmyfj": "Sin habitación",
  "disinfect.aqsj": "Incidentes de seguridad",
  "disinfect.sdzt": "Pausado manualmente",
  "disinfect.PIRztcs": "Sensor PIR suspendió la tarea. Expiró el tiempo de pausa.",
  "disinfect.pirzt": "Sensor PIR suspendió la tarea",
  "disinfect.mjpztcs": "Se movió el letrero de seguridad. Expiró el tiempo de pausa.",
  "disinfect.mjphd": "Se movió el letrero de seguridad. La tarea está suspendida.",
  "disinfect.mjpdlcs": "Se desconectó el letrero de seguridad. Expiró el tiempo de pausa.",
  "disinfect.mjpdl": "Se desconectó el letrero de seguridad",
  "disinfect.wdbhztrw": "Protección de temperatura suspendió la tarea",
  "turn.guanji": "Apagar",
  "password.word": "Restablecer la contraseña de inicio de sesión",
  "disinfect.qdsj": "Hora de inicio",
  "disinfect.tjdsrw": "Agregar tarea programada",
  "disinfect.shanchu": "Eliminar",
  "disinfect.sccdsrw": "Eliminar la tarea programada",
  "disinfect.qrsccdsrw": "¿Confirma eliminar la tarea programada?",
  "disinfect.jqrlxbyxcz": "El robot está desconectado. No se permite la operación.",
  "disinfect.zzsz": "Conectando a la base de carga",
  "disinfect.qxsz": "Cancelar tarea",
  "disinfect.gb": "Cerrar",
  "disinfect.szcg": "Conexión exitosa a la base de carga",
  "disinfect.dhqwcdz": "Navegando hacia la base de carga",
  "minbox.bentijt": "Parada de emergencia",
  "disinfect.jjszzjxrw": "¿Confirmar terminar la tarea?",
  "disinfect.qdjsrw": "Terminar",
  "disinfect.jqrbsydqqyh": "El robot ya no pertenece a la empresa y no se puede operar",
  "disinfect.yijlk": "Izquierda",
  "disinfect.jqrgj": "Apagar el robot",
  "disinfect.qdhjgj": "Después de confirmar, el robot se apagará",
  "dsrw.rwsjct": "Conflicto con la hora de una tarea programada existente, elija otra hora",
  "dsrw.rwjgq": "El tiempo de la tarea ha expirado, elija otra hora",
  "dsrw.cjsb": "Error al crear.",
  "disinfect.cpfzt": "Activar la barra anti-colisión",
  "robotInfo.ljrwcs": "Tareas acumuladas",
  "robotInfo.ljxssc": "Duración acumulada",
  "robotInfo.ckxsrw": "Ver tareas",
  "robotInfo.xsrwtj": "Estadísticas de tareas de desinfección",
  "robotInfo.zjlxsj": "Última vez desconectado",
  "robotInfo.hardwareStatus": "Estado del hardware",
  "robotInfo.noAbnormality": "Normal",
  "robotInfo.deviceError": "Falla del dispositivo",
  "robotInfo.sensorError": "Falla del sensor",
  "robotInfo.systemError": "Falla del sistema",
  "robotInfo.fault": "Falla",
  "robotInfo.nomal": "Normal",
  "robotInfo.system": "Sistema",
  "robotInfo.device": "Dispositivo",
  "robotInfo.sensor": "Sensor",
  "disinfect.rwzwfsc": "El robot está en una tarea, no se puede eliminar la habitación.",
  "agreement.text1": "Acuerdo de privacidad del Robot de Desinfección Ultravioleta ADIBOT",
  "agreement.text2": "Este acuerdo te ayudará a comprender lo siguiente:",
  "agreement.text3": "Cómo recopilamos y utilizamos",
  "agreement.text3-1": "la información relacionada con el Robot de Desinfección Ultravioleta ADIBOT",
  "agreement.text4": "Cómo actualizar este acuerdo",
  "agreement.text5": "Cómo contactarnos",
  "agreement.text6": 'TGR Solutions (dirección registrada/dirección común de oficina: 360 E 2nd St, Suite 600, Los Ángeles, California 90010, EE. UU., en adelante referido como "ADIBOT" o "nosotros").',
  "agreement.text7": "Por favor, lee y comprende este",
  "agreement.text7-1": "Acuerdo de privacidad del Robot de Desinfección Ultravioleta ADIBOT ",
  "agreement.text7-2": "cuidadosamente antes de utilizar el Robot de ",
  "agreement.text7-3": "Desinfección Ultravioleta ADIBOT.",
  "agreement.text7-4": "",
  "agreement.text8": "Haremos nuestro mejor esfuerzo para utilizar un texto fácil de entender para explicar e informarte, y usaremos fuentes en negrita para llamar su atención sobre los términos de este acuerdo que pueden tener una relación significativa con tus intereses legítimos.",
  "agreement.text8-1": "Le recomendamos que lea con atención y entienda completamente todo el contenido de este acuerdo antes de utilizar nuestro servicio por primera vez, y tome decisiones apropiadas de forma independiente. Si no está de acuerdo con el contenido de este acuerdo, no podremos proporcionarte productos y servicios completos, por lo que necesitara dejar de usarlo de inmediato.",
  "agreement.text8-2": "ADIBOT™ Robots y servicios de desinfección ultravioleta.",
  "agreement.text8-3": "Si comienza a utilizar nuestros productos o servicios, se considera que ha leído y comprendido completamente todos los contenidos de este acuerdo, y acepta que recopilemos, utilicemos y almacenemos la información de ADIBOT que adquirio de acuerdo con este contrato, para que pueda disfrutar de un servicio de alta calidad y conveniente.",
  "agreement.text9": "Los términos y palabras clave especiales involucrados en este acuerdo son:",
  "agreement.text10": "(1) La información del estado del equipo del robot:",
  "agreement.text10-1": "la información de estado de cada componente del robot;",
  "agreement.text11": "(2) La información de la versión del robot: la información de versión del cuerpo del robot y la aplicación que estás utilizando, así como la información de versión del sistema de gestión de fondo;",
  "agreement.text12": "(3) La información de la tarea del robot: la información de",
  "agreement.text12-1": "la tarea de desinfección que has creado",
  "agreement.text12-2": "incluyendo",
  "agreement.text12-3": "la hora en que se creó la tarea, la duración total de la tarea, la duración total de la desinfección, la información de estado de cada nodo durante la ejecución de la tarea y la información del mapa que",
  "agreement.text12-4": "utilizo en la tarea",
  "agreement.text13": "(4) Información de registro: una recopilación de información del equipo del robot durante un período de tiempo.",
  "agreement.text14": "1.¿Cómo recopilamos y utilizamos la información que generas durante el uso de ADIBOT?",
  "agreement.text15": "Con el fin de mantener el funcionamiento seguro y estable de los productos o servicios proporcionados, como descubrir y analizar fallos en el producto o servicio",
  "agreement.text15-1": "cuando utilize los servicios en la nube pública que ofrecemos, nuestro sistema puede recopilar automáticamente la siguiente información a través de SDK u otros métodos:",
  "agreement.text16": "Con el fin de mantener el funcionamiento seguro y estable de los productos o servicios proporcionados, como descubrir y analizar fallos en el producto o servicio",
  "agreement.text17": "(2) Información de la tarea del robot",
  "agreement.text18": "(3) Información de registro",
  "agreement.text19": "Reglas para el uso de la información de tu dispositivo",
  "agreement.text20": "(1) Utilizaremos la información recopilada para nuestros productos y servicios de acuerdo con este acuerdo de privacidad;",
  "agreement.text21": "(2) Cuando elije nuestro servicio en la nube pública, la información relevante de su robot se cargará en nuestro servidor para garantizar que pueda controlar el robot a través de la nube o ver la información del dispositivo del robot, la información de la tarea y la información del registro;",
  "agreement.text22": "(3) Cuando su robot presente fallas, después de obtener su autorización, analizaremos y eliminaremos la falla basándonos en la información del equipo, la información de la tarea y la información del registro.",
  "agreement.text23": "3. Cómo actualizar este acuerdo",
  "agreement.text24": "Nuestro acuerdo de privacidad puede cambiar.",
  "agreement.text25": "Sin su consentimiento explícito, no reduciremos sus derechos bajo este acuerdo vigente actualmente. Cuando haya un cambio importante en este acuerdo, le notificaremos. Las circunstancias que constituyen un cambio importante incluyen, pero no se limitan a:",
  "agreement.text26": "(1) Cambios significativos en el alcance y modo de los servicios que proporcionamos, como cambios en el propósito de la recopilación y uso de información, el tipo de información procesada, la forma en que se utiliza la información y la identidad de los terceros que reciben los datos;",
  "agreement.text27": "(2) Cambios significativos en nuestras entidades comerciales, estructura organizativa, estructura de propiedad, etc., como ajustes comerciales, fusiones y adquisiciones y reorganizaciones causadas por cambios en la propiedad;",
  "agreement.text28": "(3) Cambios importantes en tu derecho a participar en el procesamiento de la información y cómo ejercerlo;",
  "agreement.text29": "(4) Cambios en los métodos y principales objetos de compartir, transferir o divulgar información;",
  "agreement.text30": "(5) Cuando el informe de evaluación de riesgos de la información muestra que hay un riesgo alto;",
  "agreement.text31": "(6) Cambios en nuestro departamento responsable de seguridad de la información y su información de contacto y canales de quejas;",
  "agreement.text32": "(7) Otros cambios que puedan tener un impacto significativo en tus derechos de información.",
  "agreement.text33": "Para que reciba notificaciones de manera oportuna, se recomienda que nos notifique a tiempo cuando actualize su información de contacto. Si continúa utilizando el robot y los servicios después de que entre en vigor la actualización de este acuerdo, significa que ha leído, entendido y aceptado plenamente el acuerdo actualizado, y esta dispuesto a cumplir con él",
  "agreement.text34": "Cada vez que se actualice el acuerdo de privacidad, le notificaremos mediante un recordatorio emergente. Puede optar por verlo o ignorarlo.",
  "agreement.text35": 'Puede ver este acuerdo a través de "Configuración→Acerca de→Acuerdo de privacidad" en esta aplicación o a través de "Configuración→Acuerdo de privacidad" en el sistema ADIS. le recomendamos que revise nuestro acuerdo de privacidad cada vez que use la aplicación.',
  "agreement.text36": "4.Cómo contactarnos",
  "agreement.text37": "Si tiene alguna pregunta, comentario o sugerencia sobre este acuerdo de privacidad, por favor contáctenos a través de los siguientes métodos de contacto:",
  "agreement.text38": "Dirección: 360 E 2nd St, Suite 600, Los Ángeles, California 90010, EE. UU.",
  "disinfect.days": "Todos los días",
  "disinfect.tbzt": "Estado de sincronización",
  "disinfect.tbztcg": "Sincronización exitosa",
  "disinfect.xssjbwl": "El tiempo de desinfección no puede ser 0",
  "disinfect.yjchongqi": "Reiniciar",
  "disinfect.xiancz": "Carga por cable",
  "disinfect.xiancycm": "Carga por cable, completamente cargado",
  "disinfect.zczhong": "Cargando en la base de carga",
  "disinfect.zczhongycm": "En la base de carga, completamente cargado",
  "disinfect.jtzbyxccz": "El robot se detiene en emergencia, no se puede operar",
  "disinfect.cdzbyxccz": "El robot se está cargando, no se puede operar",
  "disinfect.qrcqjqzjsrw": "Confirmar el reinicio forzará el fin de la tarea.",
  "disinfect.jqrzzjxrw": "El robot está trabajando.",
  "disinfect.qrhjqrjcq": "Después de la confirmación, el robot se reiniciará.",
  "disinfect.jqrcq": "Reiniciar el robot",
  "disinfect.qrgjjqzjsrw": "Confirmar el apagado forzará el fin de la tarea.",
  "disinfect.rwzbyxczz": "No se puede operar durante una tarea.",
  "disinfect.zdszwgrw": "Se pueden crear hasta 20 tareas programadas.",
  "disinfect.fjmycz": "El nombre de la habitación ya existe, por favor, cámbialo.",
  "disinfect.qymycz": "El nombre del área ya existe, por favor, cámbialo.",
  "disinfect.czsbl": "Operación fallida",
  "disinfect.chengtisb": "Falla al tomar escalera electrica o elevador",
  "robotInfo.log.startWord": "Desde",
  "robotInfo.log.endWord": "hasta",
  "robotInfo.log.chooseTime": "Seleccione la fecha",
  "robotInfo.log.hasNoLog": "El registro se ha expirado en estos momentos o no existe, por favor seleccione de nuevo",
  "robotInfo.log.getFail": "Error al obtener el registro",
  "robotInfo.log.packing": "Empaquetando el registro",
  "robotInfo.log.pleaseselect": "Por favor, seleccione un registro dentro de los 7 días, de lo contrario, el archivo será demasiado grande."
};
