/*
 * @Author: your name
 * @Date: 2022-03-18 14:29:48
 * @LastEditTime: 2022-07-08 14:38:46
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\locales\zh-cn\global.ts
 */
export default {
  'global.edit': '编辑',
  'global.deleteFail': '删除失败',
  'global.editSuccess': '编辑成功',
  'global.createSuccess': '创建成功',
  'global.yes': '是',
  'global.no': '否',
  'global.error400':'参数错误',
  'global.error500':'操作失败',
  'global.error20004':'鉴权错误，token无效',
  'global.error30002':'服务器查询问题',
  'global.error40003':'账号密码错误',
  'global.error40008':'验证码错误',
  'global.error40009':'邮件发送失败'
}