/*
 * @Author: your name
 * @Date: 2022-03-18 14:08:19
 * @LastEditTime: 2022-07-08 15:07:58
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\locales\system.ts
 */
export default {
  // 'system.tab2': '安全设置',
  'system.tab2': '修改密码',
  'system.tab3': '安全问题',

  'system.password.item1': '当前密码:',
  'system.password.item2': '新密码:',
  'system.password.item3': '确认新密码:',
  'system.password.pleaseEnter': '请输入',
  'system.password.rule': '密码规则：',
  'system.password.rule1': '1.密码长度6-20位',
  'system.password.rule2': '2.密码支持数字和英文大小写，支持特殊符号',
  'system.password.confirmPasswordempty': '确认密码不能为空',
  'system.password.confirmPasswordRule1': '确认密码不得大于20位',
  'system.password.confirmPasswordRule2': '确认密码不得小于6位',
  'system.password.passwordRule3': '密码支持数字和英文大小写，支持特殊符号',
  'system.password.passwordRule4': '新密码与确认新密码不一致',
  'system.password.passwordempty': '新密码不能为空',
  'system.password.passwordRule1': '新密码不得大于20位',
  'system.password.passwordRule2': '新密码不得小于6位',
  'system.password.currentPasswordEmpty': '当前密码不能为空',
  'system.password.qaTips': '因为您修改了初始密码，我们需要您设置1个安全问题，若您忘记了密码，可通过回答安全问题重置密码。',
  'system.password.setQa': '设置安全问题',
  'system.password.toSet': '去设置',
  'system.password.changePasswordSuccess': '密码修改成功',
  'system.password.passwordWrong': '当前密码不正确',
  'system.password.changePasswordError': '密码修改失败',

  'system.qa.qaTips2': '您正在进行安全问题的修改，请先回答原安全问题：',
  'system.qa.qaTips3': '正在进行安全问题设置，若后续您忘记了密码，可通过回答改安全问题重置密码。',
  'system.qa.enterAnswer': '请输入答案',
  'system.qa.nextStep': '下一步',
  'system.qa.currentAnswer': '原问题答案不能为空',
  'system.qa.answerError': '回答错误',
  'system.qa.failRequeat': '请求出错',
  'system.qa.setQaSuccess': '安全问题设置成功',
  'system.qa.setQaError': '安全问题设置失败',
  'system.qa.getQaListError': '获取当前问题失败',
  'system.qa.choiceQa': '请选择安全问题:',

  'system.forgetPassword.title': '忘记密码',
  'system.forgetPassword.findPassword': '找回密码',
  'system.forgetPassword.tip1': '若您未进行过密码修改，请使用初始密码',
  'system.forgetPassword.tip2': '若您更改过密码，可通过回答安全问题重置密码，点击“下一步”回答安全问题',
  'system.forgetPassword.enterEnterprise': '请输入您的企业号：',
  'system.forgetPassword.pleaseQa': '请回答安全问题',
  'system.forgetPassword.correct': '回答正确',
  'system.forgetPassword.resetPassword': '登录密码已重置为12345678',
  'system.forgetPassword.noQa': '该企业号未设置安全问题。',
  'system.forgetPassword.noQaTip': '如有疑问请联系售后。',
  'system.forgetPassword.enterpriseEmpty': '企业号不能为空',
  'system.forgetPassword.enterpriseNoexistence': '企业号不存在',
  'system.forgetPassword.wrongEnterprise': '企业号错误',
  'system.forgetPassword.answerEmpty': '答案不能为空',
  'system.forgetPassword.findPasswordError': '重置密码失败',
  'system.forgetPassword.noQaTip1': '若您要修改初始密码，我们需要您先设置1个安全问题',
  'system.forgetPassword.noQaTip2': '若您忘记了密码，可通过回答安全问题找回密码',

  'system.back': '返回',
  'system.language': '语言设置',
  'system.remark': '备注',
  
  'system.forgetPassword.isAdminTip': '请问您是否为admin用户',
  'system.forgetPassword.isAdminStep1': '若您是系统管理员，可通过回答安全问题重置密码。',
  'system.forgetPassword.userRule': '若您忘记密码，请联系您的管理员修改密码',
}