/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-12 15:29:01
 * @LastEditors: zhongbin.chen
 * @LastEditTime: 2021-11-17 19:31:59
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ThemeRouter: Array<RouteRecordRaw> = [
  {
    path: '/set',
    component: Layout,
    redirect: 'noredirect',
     meta: {
      title: 'set',
      icon: '#iconcomponent'
    },
    children: [
      {
        path: 'systemSet',
        component: () => import('@/views/system/systemSet.vue'),
        name: 'SystemSet',
        meta: {
          title: 'systemSet',
          icon: 'systemNav'
        }
      }
    ]
  }
]

export default ThemeRouter
