export default {
	'user.name': 'Nombre de usuario',
	'user.placeHolder': 'Por favor, introduzca el nombre de usuario',
	'user.title': 'Usuario',
	'user.addUser': 'Crear usuario',
	'user.remark': 'Notas',
	'user.noUser': 'No ha creado ningún usuario.',
	'user.editUser': 'Editar usuario',
	'user.rule1': 'Reglas de configuración: La longitud de la contraseña es de 6 a 20 caracteres y admite números, letras mayúsculas y minúsculas, y símbolos especiales.',
	'user.ruleTitle': 'Reglas de configuración:',
	'user.rule2': '1. El nombre de usuario y la contraseña son entre 6 a 20 caracteres.',
	'user.rule3': '2. El nombre de usuario y la contraseña admiten números, letras mayúsculas y minúsculas, y símbolos especiales.',
	'user.passwordRule1': 'La contraseña no puede estar vacía.',
	'user.passwordRule2': 'Longitud de la contraseña de 6 a 20 dígitos',
	'user.passwordRule3': 'Admite números y letras mayúsculas y minúsculas en inglés, y admite símbolos especiales.',
	'user.usernameRule1': 'El nombre de usuario no puede estar vacío.',
	'user.usernameRule2': 'El nombre de usuario de 6 a 20 dígitos',
	'user.usernameRule3': 'Admite números y letras mayúsculas y minúsculas en inglés.',
	'user.delUserConfiem': '¿Estás seguro de que quieres eliminar este usuario?',
	'user.delUser': 'Eliminar usuarios',
	'user.edieUserTips': 'Por favor, tenga en cuenta:El robot necesita estar en línea para sincronizar la información del nuevo usuario. Si no puede iniciar sesión porque el robot no está conectado al Internet, primero puede iniciar sesión con la cuenta de administrador y conectar el robot a Internet.',
	'user.repeatUser': 'El nombre de usuario ya existe en la empresa, por favor, cámbielo.',
	'user.operator': 'Operador',
	'user.donotcontain': 'El nombre de usuario no puede contener símbolos especiales',
	'user.arearoom': 'Área/Habitación',
	'user.leave': '(izquierda)',
	'user.cong': 'Desde',
	'user.dao': 'Hasta',
	'user.adduser': 'Agregar operador',
	'user.createrdoperatoruser': 'El nuevo operador no tendrá autoridad para crear usuarios.',
	'user.DisableUser': 'Usuario desactivado, por favor, contacte al administrador',
	'user.jurisdiction': 'Permiso',
	'user.userstatus': 'Estado',
	'user.createduser': 'Acceso completo',
	'user.blockup': 'Desactivado',
	'user.recover': 'Restaurar',
	'user.normal': 'Activo',
	'user.RestoringaUser': 'Restaurar usuario',
	'user.recoveruser': '¿Estás seguro de que deseas restaurar este usuario?',
	'user.Successfulrecovery': 'Restaurado exitosamente',
	'user.failedrecovery': 'La recuperación falló',
	'user.notapplicableuser': '¿Está seguro de que quiere desactivar a este usuario? La cuenta está inactiva mientras esté desactivada, pero se puede usar una vez que se restaure el usuario.',
	'user.DeactivatingaUser': 'Desactivar usuario',
	'user.Deactivatingasuccessful': 'Desactivado con éxito',
	'user.Deactivatingafailed': 'Desactivación fallida',
	'user.timecalorders': 'Tiempo',
	'user.alphabeticalorder': 'Nombre',
	'user.blockuped': 'Desactivado',
	'user.email': 'Buzón',
	'user.emailRule1': 'El buzón no puede ser nulo',
	'user.emailRule2': 'Por favor, introduzca el formato de buzón correcto',
	'user.loginEmail': 'Email login',
	'user.loginPassword': 'Contraseña Login',
	'email.placeHolder': 'Por favor, introduzca un buzón',
	'user.tipsemailexists': 'El buzón ya está en uso',
	'user.tipselseerror': 'Otro error',
	'user.tipsinterneterror': 'Error de red',
	'user.tipscreateemailsuccess': 'Se ha creado correctamente el usuario del buzón',
	'user.tipsverifycodeerror': 'Error al introducir el código de verificación',
	'user.tipsresendemail': 'El correo se ha reenviado',
	'user.emailrule1': 'Reglas de configuración: La longitud de la contraseña es de 6 a 20 caracteres y admite números, mayúsculas, minúsculas y símbolos especiales.',
	'user.emailrule2': '1. El nombre de usuario y la contraseña admiten números, letras mayúsculas y minúsculas y símbolos especiales.',
	'user.resend': 'Reenviar',
	'user.complete': 'Completar',
	'user.emailregisttip': 'Introduzca el código de verificación recibido en su dirección de correo electrónico para completar el registro. Si no recibe el código de verificación, puede reenviarlo después de %ds.',
	'user.emailLogintip1': 'Hemos enviado un mensaje de confirmación a su dirección de correo electrónico. Por favor, introduzca el código de verificación recibido en el correo electrónico para verificar el inicio de sesión.',
	'user.emailLogintip2': 'Si no recibes el código de verificación, puedes reenviarlo después de %ds.',
	'user.noverifyday30': 'No verificado durante 30 días',
	'user.loginConfirm': 'Confirmar',
	'user.name_or_email':'Nombre de usuario/Buzón',
	'user.name_or_email_placeholder':'Introduzca el nombre de usuario/Buzón'
};