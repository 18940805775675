/*
 * @Author: ying.hong ying.hong@ubtrobot.com
 * @Date: 2022-07-08 14:26:44
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-04-19 17:07:22
 * @FilePath: \ADIS\src\locales\zh-cn\user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  'user.name': '用户名',
  'user.placeHolder': '请输入用户名',
  'user.title': '用户管理',
  'user.addUser': '创建用户',
  'user.remark': '备注',
  'user.noUser': '您还没创建任何用户',
  'user.editUser': '编辑用户',
  'user.rule1': '设定规则：密码长度6-20位，支持数字和英文大小写，支持特殊符号',
  'user.ruleTitle': '设定规则：',
  'user.rule2': '1.用户名长度6-20位，支持数字和英文字母，支持常见特殊符号',
  'user.rule3': '2.密码长度6-20位，支持数字和英文大小写，支持常见特殊符号',
  'user.passwordRule1': '密码不能为空',
  'user.passwordRule2': '密码长度6-20位',
  'user.passwordRule3': '支持数字和英文大小写，支持特殊符号',
  'user.usernameRule1': '用户名不可为空',
  'user.usernameRule2': '用户名需为6-20位',
  'user.usernameRule3': '支持数字和英文大小写',
  'user.delUserConfiem': '确认删除该用户吗？',
  'user.delUser': '删除用户',
  'user.edieUserTips': '请注意：<br/>机器人需联网后才可同步新增的用户名信息。若因机器人未联网而无法登录，可以先使用admin账号登录进行联网操作。',
  'user.repeatUser': '企业已存在该用户名，请重新命名',
  'user.operator': '操作人',
  'user.donotcontain': '用户名不可含有特殊符号',
  'user.arearoom': '区域/房间',
  'user.leave': '(已离开)',
  'user.cong': '从',
  'user.dao': '到',
  'user.adduser': '新增操作人',
  'user.createrdoperatoruser': '创建新操作人将创建一个无用户管理权限的账户',
  'user.DisableUser': '用户已停用，请联系管理员',
  'user.jurisdiction': '权限',
  'user.userstatus': '用户状态',
  'user.createduser': '可创建操作人',
  'user.blockup': '停用',
  'user.recover': '恢复',
  'user.normal': '正常',
  'user.RestoringaUser': '恢复用户',
  'user.recoveruser': '确认恢复该用户吗？',
  'user.Successfulrecovery': '恢复成功',
  'user.failedrecovery': '恢复失败',
  'user.notapplicableuser': '确认停用该用户吗？停用期间该账号不可用，停用解除后可继续使用',
  'user.DeactivatingaUser': '停用用户',
  'user.Deactivatingasuccessful': '停用成功',
  'user.Deactivatingafailed': '停用失败',
  'user.timecalorders': '时间排序',
  'user.alphabeticalorder': '字母排序',
  'user.blockuped': '停用',
  'user.email':'邮箱',
  'user.emailRule1':'邮箱不能为空',
  'user.emailRule2':'请输入正确的邮箱格式',
  'user.loginEmail':'邮箱登录',
  'user.loginPassword':'密码登录',
  'email.placeHolder': '请输入邮箱',
  'user.tipsemailexists':'提示: 邮箱已被使用',
  'user.tipselseerror':'提示: 其它错误',
  'user.tipsinterneterror':'提示: 网络错误',
  'user.tipscreateemailsuccess':'提示: 创建邮箱用户成功',
  'user.tipsverifycodeerror': '验证码输入错误',
  'user.tipsresendemail': '提示: 已重新发送邮件',
  'user.emailrule1':'设定规则：密码长度6-20位，支持数字和英文大小写，支持特殊符号',
  'user.emailrule2':'1. 密码长度6-20位，支持数字和英文大小写，支持常见特殊符号',
  'user.resend':'重发',
  'user.complete':'完成',
  'user.emailregisttip':'请输入邮箱收到的验证码，完成注册。若未收到验证码，%ds后可重发。',
  'user.emailLogintip1':'我们给你们的邮箱发送了确认邮件。请输入邮件收到的验证码进行登录验证。',
  'user.emailLogintip2':'若未收到验证码，%ds后可重发。',
  'user.noverifyday30':'30天内不再验证',
  'user.loginConfirm':'确认',
  'user.name_or_email':'用户名/邮箱',
  'user.name_or_email_placeholder':'请输入用户名/邮箱'
}