<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-02 14:34:44
-->

<template>
  <div>
    <el-dropdown>
      <svg
        class="icon"
        aria-hidden="true"
        font-size="45px"
        :class="{'svg-color': isWhite}"
      >
        <use xlink:href="#iconzhongyingwen" />
      </svg>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in languages"
            :key="item.value"
            :disabled="language===item.value"
            @click="handleSetLanguage(item.value)"
          >
            <span>{{ item.name }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang="ts">
import { useStore } from '@/store'
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import i18n from '@/locales/index'
let _t = i18n.global.t
type Language = {
    name: string
    value: string
}

export default defineComponent({
  props: {
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const { locale } = useI18n()

    const state = reactive({
      languages: [{ name: 'en', value: 'en' }, { name: '中文', value: 'zh-cn' }] as Array<Language>,
      handleSetLanguage: (lang: string) => {
        // locale.value = lang

        store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE, lang)
        location.reload()
        // ElMessage.closeAll()
        // ElMessage({
        //   message: _t('system.language'),
        //   type: 'success'
        // })
      }
    })
    const language = computed(() => {
      return store.state.app.language
    })
    return {
      ...toRefs(state),
      language
    }
  }
})

</script>

<style lang="scss" scoped>
.svg-color{
  fill: rgba(0, 0, 0, 0.85);
}
</style>
