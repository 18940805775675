

export enum UserActionTypes {
  ACTION_LOGIN = 'ACTION_LOGIN',
  ACTION_RESET_TOKEN = 'ACTION_RESET_TOKEN',
  ACTION_GET_USER_INFO = 'ACTION_GET_USER_INFO',
  ACTION_CHANGE_ROLES = 'ACTION_CHANGE_ROLES',
  ACTION_LOGIN_OUT = 'ACTION_LOGIN_OUT',
  ACTION_Authority = 'ACTION_Authority',
  ACTION_LOGIN_EMAIL = 'ACTION_LOGIN_EMAIL'
}
