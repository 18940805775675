
import { getToken } from '@/utils/cookies'

export interface UserState{
    token: string
    name: string
    company: string
    avatar: string
    introduction: string
    roles: string[]
    email: string,
    authority:string|Number
}

export const state: UserState = {
  token: getToken() || '',
  name: '',
  company: '',
  avatar: '',
  introduction: '',
  roles: [],
  email: '',
  authority:''
}
