/*
 * @Description: 
 * @Author: 郭长生
 * @Date: 2024-01-08 17:07:12
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-02-20 16:21:43
 */
/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: zhongbin.chen
 * @LastEditTime: 2022-01-10 10:06:05
 */

import { createI18n } from 'vue-i18n' // import from runtime only

import { getLanguage } from '@/utils/cookies'

import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
import elementEsLocale from 'element-plus/lib/locale/lang/es';

// User defined lang
import enLocale from './en'
import zhLocale from './zh-cn'
import esLocale from './es';

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale
  },
  es: {
    ...esLocale,
    ...elementEsLocale
  }
}

export const getLocale = () => {
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    return cookieLanguage
  }
  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }

  // Default language is english
  return 'zh'
}

const i18n = createI18n({
  locale: getLocale(),
  messages: messages
})

export default i18n
