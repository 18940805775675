<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: zhongbin.chen
 * @LastEditTime: 2022-01-06 16:45:30
-->

<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import {
  reactive,
  nextTick,
	toRefs,
  onMounted,
  provide
} from "vue";
export default {
  setup() {
    const state = reactive({
			isRouterAlive:  true,
      reload() {
        state.isRouterAlive = false;
        nextTick(() => (state.isRouterAlive = true));
      }  
    });
    provide('reload', state.reload())
    return {
      ...toRefs(state)
    };
  }
};
// window.onload = function (){   
//   document.body.onkeydown=function(event){
//     if(event.keyCode==13||event.keyCode==32){
//         return false;
//     }
//   }
// };
</script>

