/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @LastEditTime: 2022-05-12 14:29:05
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const regionRouter: Array<RouteRecordRaw> = [
  {
    path: '/region',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'regionManage',
        component: () => import('@/views/region/regionManage.vue'),
        name: 'Region',
        meta: {
          title: 'region',
          icon: 'regionNav'
        }
      }, {
        path: 'regionManageroom',
        name:'Roomdetail',
        component: () => import('@/views/region/roomDetail.vue'),
        meta: {
          title: '房间详情',
          noCache: true,
          hidden: true
        }
      }
    ]
  }
]

export default regionRouter
