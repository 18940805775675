/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @LastEditTime: 2022-07-12 18:38:09
 */


import project from './zh-cn/project'
import global from './zh-cn/global'
import system from './zh-cn/system'
import user from './zh-cn/user'

export default {
  ...project,
  ...global,
  ...system,
  ...user,
}
