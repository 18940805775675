export default {
	"route.dashboard": "Dashboard",
	"route.robot": "Robot",
	"route.disinfect": "Tasks",
	"route.errorPages": "Error page",
	"route.page401": '401',
	"route.page404": '404',
	"route.errorLog": "Error log",
	"route.region": "Area",
	"route.i18n": "Globalization",
	"route.systemSet": "Settings",
	'route.manage': 'User',
	"navbar.logOut": "Log out",
	"login.title": "Login by enterprise account",
	"login.logIn": "Login",
	"login.username": "Username",
	"login.password": "Password",
	"login.company_id": "Enterprise account",
	"login.tip_company": "Please input enterprise account",
	"login.tip_pwd": "Please input password",
	"login.loginAgree": "Login means you have read and agree ",
	"login.termsService": "Privacy Agreement",
	"login.privacyAgreement": "Privacy Agreement",
	"login.and": "and",
	"login.companyProducts": " Produced by UBTECH Robotics Corp.",
	"robot.projectTtitle": "Robot Management System",
	"robot.manage": "Robot Management",
	"robot.search": "Search",
	"robot.factorySerialNumber": "Factory serial number/software serial number",
	"robot.online": "Online",
	"robot.offline": "Offline",
	"robot.remainingPower": "Remaining battery",
	"robot.cumulativeDisinfectTask": "Cumulative tasks",
	"robot.cumulativeDisinfectTime": "Cumulative duration",
	"robot.second": "tasks",
	"robot.hours": "h",
	"robot.minutes": "min",
	"robot.seconds": "s",
	"robot.InSitu_disinfect": "Disinfection on the spot",
	"robot.explore_disinfect": "Auto-explorative disinfection",
	"robot.deploy_disinfect": "Use-map disinfection",
	"robot.start": "Start",
	"robot.dock": "Moving to the charging dock",
	"robot.leave_dock": "Getting off the charging dock",
	"robot.locomotion": "Control movement",
	"robot.location": "Positioning",
	"robot.navigation": "Navigating",
	"robot.navigation_dock": "Navigating back to charging dock",
	"robot.navigation_origin": "Back to the beginning position",
	"robot.voicebroadcast": "Voice broadcast",
	"robot.countdown": "Countdown",
	"robot.disinfect": "Disinfection",
	"robot.explore": "Auto-explore",
	"robot.sleep": "Wait in place",
	"robot.charging": "Charging",
	"robot.pause": "Pause",
	"robot.end": "End",
	"robot.idle": "Idle",
	"robot.in_operation": "Running",
	"robot.hangup": "Hanged",
	"robot.failed": "Failed",
	"robot.canceled": "Terminated",
	"robot.finish": "Completed",
	"robot.safeguard": "Security incident paused",
	"robot.manual": "App paused",
	"robot.safeguard_pir": "PIR paused",
	"robot.safeguard_ai": "Camera paused",
	"robot.safeguard_access_control": "Safety sign moved",
	"robot.access_control": "Safety sign disconnected",
	"robot.high_temperature": "High temperature protection",
	"robot.detail": "Robot details",
	"robot.getLog": "Get log",
	"robot.onlineStatus": "Online status",
	"robot.factorySerial": "Factory SN",
	"robot.softwareSerial": "Software SN",
	"robot.firstActivationTime": "First activation time",
	"robot.lastOnlineTime": "Last time online",
	"robot.systemStatus": "System status",
	"robot.doorControl": "Safety sign",
	"robot.doorControl2": "Safety sign",
	"robot.doorControlnone": 'Standard access',
	"robot.doorControl_mac": "Safety sign MAC",
	"robot.network": "Network",
	"robot.edition": "Version number",
	"robot.app_edition": "App version number",
	"robot.timedTask": "Timed task",
	"robot.getting": "Acquiring",
	"robot.rosLog": "ROS log",
	"robot.navLog": "Navigation log",
	"robot.lately": "Recent",
	"robot.obtain": "Acquire",
	"robot.choice": "Select",
	"robot.obtainSucess": "Acquired successfully",
	"robot.clickDownload": "Download",
	'robot.viewDetails': 'View Details',
	'robot.robotOfflineNo': 'The robot is offline',
	'robot.resetSure': 'Are you sure to reset',
	'robot.resetLoginPwd': 'Confirm that the login password of the robot will be reset to 123456',
	'robot.robotEdition': 'Robot version number',
	'robot.resetLoginPwdSuccess': 'The robot login password has been reset to 123456',
	'robot.resetLoginPwdTip': 'Please log in again on the tablet and set a new password',
	'robot.robotOfflineTip1': 'The robot is offline and cannot create scheduled tasks',
	'robot.robotOfflineTip2': 'The robot is offline and pile return is not allowed',
	'robot.robotOfflineTip3': 'The robot is offline and task creation is not allowed',
	'robot.robotOfflineTip4': 'The robot is offline and cannot be shut down',
	'robot.unpaired': 'Unpaired',
	'robot.notConnected': 'Not Connected',
	'robot.connected': 'Connected',
	'robot.fourday': 'Four days',
	'robot.fiveday': 'Five days',
	'robot.sixday': 'Six days',
	'robot.sevenday': 'Seven days',
	'robot.changeNameTitle': 'Edit robot name',
	'robot.changeNameSuccess': 'Successfully modified',
	'robot.robotNoExist': 'Robot doesn’t exist',
	'robot.changeNameFail': 'Fail to edit',
	'robot.delRobotTips': 'After deletion, the task data of the robot will be cleared',
	'robot.delRobotTitle': 'Are you sure you want to delete this robot',
	'robot.delRobotDone': 'Robot deleted',
	'robot.delRobotFail': 'The robot is not in the left state, forbidden to delete',
	'robot.useOriName': 'Use original name',
	'robot.ddisName': 'Wi-Fi name',

	'system.operation': 'Operation',
	"system.set": "System settings",
	"system.robotManage": "Robot permission management",
	"system.robotRule1": "Allow the robot to create, modify or delete room information",
	"system.robotRule2": "Allow the robot to change the area attribution",
	"system.setSuccess": "Set successfully",
	"system.cancel": "Cancel",
	"system.determine": "Confirm",
	'system.companyNoExis': "Enterprise number does not exist",
	'system.userNoExis': "User does not exist",
	'system.passwordNoExis': "The account or password is incorrect, you will be locked for 10 minutes if you make 5 mistakes. You have made mistakes for N times.",

	"settings.title": "System layout configuration",
	"settings.theme": "Theme color",
	"settings.showTagsView": "Show Tags-View",
	"settings.showSidebarLogo": "Show sidebar Logo",
	"settings.fixedHeader": "Fixed Header",
	"settings.sidebarTextTheme": "Theme color of sidebar text ",

	"system.status": "Status",
	"system.createTime": "Start Time",
	"system.search": "Search",
	"system.reset": "Reset",
	"system.open": "Show",
	"system.retract": "Hide",
	"system.inProgress": "In Progress",
	"system.normalCompletion": "Completed",
	"system.abnormalEnd": "Abnormal termination",
	"system.create": "Create",
	"system.delete": "Delete",
	"system.rename": "Rename",
	"system.change": "Change",
	"system.add": "Add",
	"system.createSuccess": "Created successfully",
	"system.changeSuccess": "Edited successfully",
	"system.deleteSuccess": "Deleted successfully",
	"system.tips": "Notification",
	"system.noData": "No relevant data",
	"system.startTime": "Start time",
	"system.endTime": "End time",
	"system.total": "All",
	"system.choice": "Please select",
	'system.noTask': 'No Task',
	'system.loginTimeout': 'Login Timeout',
	'system.page': 'Total XX ',

	"disinfect.room": "Room",
	"disinfect.pattern": "Mode",
	"disinfect.totalTime": "Disinfection duration",
	"disinfect.existingMap": "Use map",
	"disinfect.starting": "In Progress",
	"disinfect.detail": "Task details",
	'disinfect.export': 'Export',
	'disinfect.exportTip': 'Export all data currently filtered?',
	'disinfect.exportTitle': 'Export',
	'disinfect.exportFail': 'Fail to export',

	"region.area": "Area",
	"region.room": "Room",
	"region.noRoom": "You have not created any rooms",
	"region.createNow": "Create now",
	"region.noArea": "You have not created any areas",
	"region.changeArea": "Change area name",
	"region.createArea": "Create new area",
	"region.changeRoom": "Change room name",
	"region.createRoom": "Create new room",
	"region.enterArea": "Please input area name",
	"region.enterRoom": "Please input room name",
	"region.modalTip1": "Timed tasks and maps related to this room will be deleted by deleting this room. Are you sure you want to delete this room?",
	"region.modalTip2": "Please delete all rooms in the area before deleting the area",
	"region.modalTip3": "Are you sure to delete this area?",
	"region.delArea": "Delete area",
	'region.delRoom': "Delete room",
	'region.noMapTip': 'You have not uploaded any maps',
	'region.sourceRobot': 'Source robot',
	'region.UpdateTime': 'Upload time',
	'region.reNewMap': 'Change map name',
	'region.enterNewMapName': 'Enter new map name',
	'region.enterName': 'Please enter a name',
	'region.confirmDelMap': 'Are you sure you want to delete this map?',
	'region.delMap': 'Delete map',
	'region.reNameMapSuccess': 'Rename succeeded',
	'region.mapNameconflict': 'The same map name already exists',
	'region.delMapNoRoom': 'Room does not exist',

	"dataReport.robotNum": "Number of robots",
	"dataReport.cumulativeTaskTimes": "Cumulative tasks",
	"dataReport.disinfectDuration": "Cumulative duration",
	'dataReport.platform': '',
	"dataReport.title1": "Number of tasks & disinfection duration in areas",
	"dataReport.title2": "Number of tasks/disinfection duration in rooms",
	"dataReport.title3": "Robot usage rate",
	"dataReport.today": "Today",
	"dataReport.lastWeek": "Last week",
	"dataReport.lastMonth": "Last month",
	"dataReport.lastYear": "Last year",
	'dataReport.taskCount': 'Number of tasks',
	'dataReport.taskTime': 'Task duration',
	'dataReport.disinfectTime': 'Disinfection duration',
	'dataReport.taskCountTotal': 'Total number of tasks',
	'dataReport.rate': 'Utilization rate',
	'dataReport.intervalTip': 'You can only select 31 days at most',

	"task.yjhz": "Return to charging dock",
	"minbox.aqsjzt": "Security incident paused the task",
	"minbox.sdjieshu": "Manually terminated",
	"minbox.mjtz": "Safety sign emergency button terminated task",
	"minbox.stchufa": "Chassis unlocked",
	'minbox.mjdl': 'Safety sign disconnect',
	"minbox.pirjs": "PIR pause timeout",
	"minbox.sxtjs": "Camera pause timeout",
	"minbox.mjjs": "Safety sign pause timeout",
	"minbox.dici": "Trigger geomagnetic strip",
	"minbox.fangzt": "Trigger anti-collision bar",
	"minbox.dlguoji": "Motor overcurrent",
	"minbox.xyjc": "Cliff detection",
	"minbox.sbgz": "Device failure",
	"minbox.daohangshibai": "Navigation failed",
	"minbox.dingweishibai": "Failed to position",
	"minbox.jiantushibai": "Failed to build map",
	"minbox.xiazhuangsb": "Failed to leave the charging dock",
	"minbox.shangzhuangsb": "Failed to connect the charging dock",
	"minbox.cgqyc": "Sensors abnormal",
	"minbox.dhcscw": "Navigation parameter error",
	"minbox.dhfwqcw": "Navigation server error",
	"minbox.mbdbkd": "Unreachable location",
	"minbox.dhmkcshsb": "Navigation module failed to initialize",
	"minbox.zbdcdz": "Can't find charging dock",
	"minbox.yczd": "Abnormal interruption",
	"minbox.qita": "Others",
	"creted.renwu": "Create task",
	"creted.cjdsrw": "Create timed tasks",
	"creted.bjdsrw": "Edit timed tasks",
	"creted.zztansuo": "Auto-explorative disinfection",
	"creted.ydxs": "Disinfection on the spot",
	"creted.xssj": "Disinfection duration",
	"creted.ddxssj": "Disinfection duration of each point",
	"creted.aqjc": "Safety settings",
	"creted.mjp": "Safety sign",
	"creted.bdzthf": "End task automatically after pause duration exceeds",
	"creted.cj": "Create",
	"creted.cjcgzztz": "Created successfully, redirecting",
	"creted.mjpwljcjsb": "Safety sign is not connected. Failed to create.",
	'creted.openuv': 'UV light on while navigating',
	'creted.openuvwarning': 'PIR is not available during navigation, please make sure no one approaches during the task ',
	'creted.openuvOn': 'Turn on',
	'creted.openuvOff': 'Turn off',
	"creted.dqyjxrw": "Task in progress. Failed to create.",
	"creted.xtlbcwcjsb": "System internal error. Failed to create.",
	"creted.sbgzcjsb": "Device failure. Failed to create.",
	"creted.jqjtqbcan": "The robot is stopped in emergency. Please pull out the emergency stop button to dismiss.",
	"creted.dcyccjsb": "Geomagnetic is abnormal. Failed to create.",
	"creted.fztyccjsb": "The anti-collision bar is abnormal. Failed to create.",
	"creted.dpdjglcjsb": "Motor Overcurrent/Temperature is too high. Failed to create.",
	"creted.zdyccjsb": "Diagnosis is abnormal. Failed to create.",
	"creted.zczcjsb": "Robot is on the charging dock. Failed to create.",
	"creted.xczcjsb": "Robot wired charging. Failed to create.",
	"creted.dqdtbczwfcj": "Current map does not exist. Failed to create.",
	"creted.dhmkyxzcjsb": "Navigation module is running. Failed to create.",
	"creted.cgqyccjsb": "Sensors abnormal. Failed to create.",
	"creted.jqrwdggcjsb": "The robot's temperature is too high. Failed to create.",
	"creted.jqrdlxy": "Robot power is less than 10%, unable to create",
	"creted.rwlx": "Task type",
	"creted.dcrw": "Single task",
	"creted.cfrw": "Repeat task",
	"creted.xzrq": "Select date",
	"creted.cfgh": "Select day(s)",
	"creted.qxzcfsj": "Please select",
	"creted.xzsj": "Select time",
	"creted.cfsj": "Select time",
	"creted.zhouri": "Sun.",
	"creted.zhouyi": "Mon.",
	"creted.zhouer": "Tue.",
	"creted.zhousan": "Wed.",
	"creted.zhousi": "Thu.",
	"creted.zhouwu": "Fri.",
	"creted.zhouliu": "Sat.",
	"creted.bcxg": "Save changes",
	"disinfect.xsrwxq": "Task details",
	"disinfect.jxrw": "Restore task",
	"disinfect.ztrw": "Suspend task",
	"disinfect.jsrw": "Terminate task",
	"disinfect.rwzsc": "Task duration",
	"disinfect.xsditu": "Disinfection map",
	"disinfect.gqyzwdt": "No map",
	"disinfect.rwjl": "Task records",
	"disinfect.rwks": "Task started",
	"disinfect.tansuo": "Exploring",
	"disinfect.huidaocdz": "Back to charging dock",
	"disinfect.wfddxsd": "Can't reach Disinfection point",
	"disinfect.wfdddhd": "Can't reach Navigation point",
	"disinfect.jqrzzcd": "The robot is charging",
	"disinfect.xszy": "Disinfection",
	"disinfect.dddhd": "Reach navigation point",
	"disinfect.rwjs": "Task terminated",
	"disinfect.rwwc": "Task completed",
	"disinfect.xgdsrwcgqsh": "Successful to edit",
	"disinfect.dqmyfj": "No room",
	"disinfect.aqsj": "Safety incidents",
	"disinfect.sdzt": "Manually paused",
	"disinfect.PIRztcs": "PIR suspended the task. Pause timeout.",
	"disinfect.pirzt": "PIR suspended the task",
	"disinfect.mjpztcs": "Safety sign moved. Pause timeout.",
	"disinfect.mjphd": "Safety sign moved. The task is suspended.",
	"disinfect.mjpdlcs": "Safety sign disconnected. Pause timeout.",
	"disinfect.mjpdl": "Safety sign disconnected",
	"disinfect.wdbhztrw": "Temperature protection suspended the task",
	"turn.guanji": "Shutdown",
	"password.word": "Reset Login password",
	"disinfect.qdsj": "Start time",
	"disinfect.tjdsrw": "Add timed task",
	"disinfect.shanchu": "Delete",
	"disinfect.sccdsrw": "Delete the timed task",
	"disinfect.qrsccdsrw": "Do you confirm to delete the timed task?",
	"disinfect.jqrlxbyxcz": "The robot is offline. The operation is not allowed.",
	"disinfect.zzsz": "Connecting to the charging dock",
	"disinfect.qxsz": "Cancel task",
	"disinfect.gb": "Close",
	"disinfect.szcg": "Successful to connect to the charging dock",
	"disinfect.dhqwcdz": "Navigating to the charging dock",
	'minbox.bentijt': 'Emergency stop',
	'disinfect.jjszzjxrw': 'Confirm to terminate the task？',
	'disinfect.qdjsrw': 'Terminate',
	'disinfect.jqrbsydqqyh': 'The robot no longer belongs to the enterprise and cannot be operated',
	'disinfect.yijlk': 'Left',
	'disinfect.jqrgj': 'Shutdown robot',
	'disinfect.qdhjgj': 'After confirming, the robot will shut down',
	'dsrw.rwsjct': 'Conflict with the time of an existing timed task, please choose another time',
	'dsrw.rwjgq': 'The task time has expired, please choose another time',
	'dsrw.cjsb': 'Failed to create.',
	'disinfect.cpfzt': 'Trigger anti-collision bar',
	'robotInfo.ljrwcs': 'Cumulative tasks',
	'robotInfo.ljxssc': 'Cumulative duration',
	'robotInfo.ckxsrw': 'View tasks',
	'robotInfo.xsrwtj': 'Disinfection task statistics',
	'robotInfo.zjlxsj': 'Last time offline',
	'robotInfo.hardwareStatus': 'Hardware status',
	'robotInfo.noAbnormality': 'Normal',
	'robotInfo.deviceError': 'Device failure',
	'robotInfo.sensorError': 'Sensor failure',
	'robotInfo.systemError': 'System failure',
	'robotInfo.fault': 'Failure',
	'robotInfo.nomal': 'Normal',
	'robotInfo.system': 'System',
	'robotInfo.device': 'Device',
	'robotInfo.sensor': 'Sensor',
	'disinfect.rwzwfsc': 'The robot is on a task, the room cannot be deleted',

	'agreement.text1': 'Privacy Agreement of ADIBOT Ultraviolet Disinfection Robot',
	'agreement.text2': 'This agreement will help you understand the following:',
	'agreement.text3': 'How do we collect and use ',
	'agreement.text3-1': 'ADIBOT Ultraviolet Disinfection Robot related information',
	'agreement.text4': 'How to update this agreement',
	'agreement.text5': 'How to contact us',
	'agreement.text6': 'UBTECH Robotics Corp. (registered address/common office address: 767 S Alameda St, Suite 250, Los Angeles, California 90021 USA, hereinafter referred to as "Ubtech" or "we").',
	'agreement.text7': 'Please read and understand this ',
	'agreement.text7-1': '"Privacy Agreement of ADIBOT Ultraviolet Disinfection Robot" ',
	'agreement.text7-2': 'carefully before using ',
	'agreement.text7-3': 'ADIBOT Ultraviolet Disinfection Robot.',
	'agreement.text7-4': '',
	'agreement.text8': 'We will try our best to use easy-to-understand text to explain and inform you, and use bold fonts to draw your attention to the terms of this agreement that may have a significant relationship with your legitimate interests. ',
	'agreement.text8-1': 'We strongly recommend that you carefully read and fully understand all the contents of this agreement before using our service for the first time, and make appropriate choices independently. If you do not agree to the content of this agreement, we will not be able to provide you with complete products and services, and you also need to immediately stop using ',
	'agreement.text8-2': 'ADIBOT™ Ultraviolet Disinfection Robots and services. ',
	'agreement.text8-3': 'If you start to use our products or services, it is deemed that you have fully and completely read and understood all the contents of this agreement, and agree that we collect, use, and store the ADIBOT information you purchased in accordance with this agreement , So that you can enjoy high-quality and convenient service. ',
	'agreement.text9': 'The special terms and keywords involved in this agreement are:',
	'agreement.text10': '(1) The equipment status information of the robot: ',
	'agreement.text10-1': 'the status information of each component of the robot;',
	'agreement.text11': '(2) The version information of the robot: the version information of the robot body and App you are using, and the version information of the background management system;',
	'agreement.text12': '(3) The task information of the robot: the information of ',
	'agreement.text12-1': 'the disinfection task you created, ',
	'agreement.text12-2': 'including ',
	'agreement.text12-3': 'the time when the task was created, the total duration of the task, the total duration of the disinfection, the status information of each node during the execution of the task, and the map information ',
	'agreement.text12-4': 'you used in the task;',
	'agreement.text13': '(4) Log information: a collection of robot equipment information over a period of time.',
	'agreement.text14': '1. How do we collect and use the information you generated during the use of ADIBOT?',
	'agreement.text15': 'In order to maintain the safe and stable operation of the products or services provided, such as discovering and analyzing product or service failures, ',
	'agreement.text15-1': 'when you use the public cloud services we provide, our system may automatically collect the following information through SDK or other methods:',
	'agreement.text16': '(1) Robot equipment information: including robot equipment status information and robot version information',
	'agreement.text17': '(2) Task information of the robot',
	'agreement.text18': '(3) Log information',
	'agreement.text19': '2. Rules for the use of your device information',
	'agreement.text20': '(1) We will use the collected information for our products and services in accordance with this privacy agreement;',
	'agreement.text21': "(2) When you choose our public cloud service, the relevant information of your robot will be uploaded to our server to ensure that you can control the robot through the cloud or view the robot's device information, task information and log information;",
	'agreement.text22': '(3) When your robot fails, after obtaining your authorization, we will analyze and eliminate the fault based on the equipment information, task information and log information.',
	'agreement.text23': '3. How to update this agreement',
	'agreement.text24': 'Our privacy agreement may change.',
	'agreement.text25': 'Without your explicit consent, we will not reduce your rights under this agreement currently in force. When there is a major change in this agreement, we will notify you. The circumstances that constitute a major change include but are not limited to:',
	'agreement.text26': '(1) Significant changes have occurred in the scope and mode of the services we provide, such as changes in the purpose of collecting and using information, the type of information processed, the way the information is used, and the identity of the third party receiving the data;',
	'agreement.text27': '(2) Significant changes in our business entities, organizational structure, ownership structure, etc., such as business adjustments, mergers and acquisitions and reorganizations caused by owner changes;',
	'agreement.text28': '(3) Your right to participate in information processing and how to exercise it has undergone major changes;',
	'agreement.text29': '(4) There are changes in the methods and main objects of information sharing, transfer or public disclosure;',
	'agreement.text30': '(5) When the information risk assessment report shows that there is a high risk;',
	'agreement.text31': '(6) Our responsible department in charge of information security and its contact information and complaint channels have changed;',
	'agreement.text32': '(7) Other changes that may have a significant impact on your information rights.',
	'agreement.text33': 'In order for you to receive notifications in a timely manner, it is recommended that you notify us in time when the contact information is updated. If you continue to use the robot and services after the update of this agreement takes effect, it means that you have fully read, understood and accepted the updated agreement and are willing to be bound by the updated agreement.',
	'agreement.text34': 'Whenever the privacy agreement is updated, we will notify you via a pop-up reminder. You can choose to view or ignore it.',
	'agreement.text35': 'You can view this agreement through "Settings→About→Privacy Agreement" in this App or through "Settings→Privacy Agreement" in the ADIS system. We encourage you to check our privacy agreement every time you use the App.',
	'agreement.text36': '4. How to contact us',
	'agreement.text37': 'If you have any questions, comments or suggestions about this privacy agreement, please contact us through the following contact methods:',
	'agreement.text38': 'Address: 767 S Alameda St, Suite 250, Los Angeles, California 90021 USA',
	'disinfect.days': 'everyday',
	'disinfect.tbzt': 'Sync status',
	'disinfect.tbztcg': 'Synchronization successful',
	'disinfect.xssjbwl': 'The disinfection time cannot be 0',
	'disinfect.yjchongqi': 'Reboot',
	'disinfect.xiancz': 'Wired charging',
	'disinfect.xiancycm': 'Wired charging, fully charged',
	'disinfect.zczhong': 'Charging on charging dock',
	'disinfect.zczhongycm': 'On charging dock, fully charged',
	'disinfect.jtzbyxccz': 'The robot stops in emergency, unable to operate',
	'disinfect.cdzbyxccz': 'The robot is charging, unable to operate',
	'disinfect.qrcqjqzjsrw': 'Confirming to reboot will force the task to an end.',
	'disinfect.jqrzzjxrw': 'The robot is working.',
	'disinfect.qrhjqrjcq': 'After confirmation the robot will restart',
	'disinfect.jqrcq': 'Reboot robot',
	'disinfect.qrgjjqzjsrw': 'Confirming the shutdown will force the task to an end.',
	'disinfect.rwzbyxczz': 'Unable to operate during a task',
	'disinfect.zdszwgrw': 'Up to 20 timed tasks can be created',
	'disinfect.fjmycz': 'Room name existed, please rename',
	'disinfect.qymycz': 'Area name existed, please rename',
	'disinfect.czsbl': 'Operation failed',
	'disinfect.chengtisb': 'Take the stairs failure',

	'robotInfo.log.startWord': 'From',
	'robotInfo.log.endWord': 'To',
	'robotInfo.log.chooseTime': 'Select date',
	'robotInfo.log.hasNoLog': 'The log at this time has expired or does not exist, please select again',
	'robotInfo.log.getFail': 'Fail to get log',
	'robotInfo.log.packing': 'Packing log',
	'robotInfo.log.pleaseselect': 'Please select a log within 7 days, otherwise the file is too large.'
}
