export default {
	'global.edit': 'Editar',
	'global.deleteFail': 'Fallo al eliminar',
	'global.editSuccess': 'Editado exitosamente',
	'global.createSuccess': 'Creado exitosamente',
	'global.yes': 'Si',
	'global.no': 'No',
	'global.error400': 'Error de parámetro',
	'global.error500': 'Operación fallida',
	'global.error20004': 'Error de autenticación, token no válido',
	'global.error30002': 'Problema de consulta al servidor',
	'global.error40003': 'Error de contraseña de la cuenta',
	'global.error40008': 'Error de código de verificación',
	'global.error40009': 'Error de entrega de correo'
};
