
import { ActionTree, ActionContext } from 'vuex'
import { RootState, useStore } from '@/store'
import { state, UserState } from './state'
import { Mutations } from './mutations'
import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import { loginEmailRequest, loginRequest, userInfoRequest } from '@/apis/user'
import { removeToken, setRemember, setToken } from '@/utils/cookies'
import { PermissionActionType } from '../permission/action-types'
import router, { resetRouter } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import userImg from '@/assets/images/home/userImg.png'
import { ElMessage } from 'element-plus';
import i18n from '@/locales/index'
let _t = i18n.global.t

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<UserState, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { username: string, password: string, company_id: string }
  ): void
  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext
  ): void
  [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext
  ): void
  [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext, role: string
  ): void
  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext,
  ): void
  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext,
  ): void
  [UserActionTypes.ACTION_LOGIN_EMAIL](
    { commit }: AugmentedActionContext,
    info: { company_id:string, email: string, token: string, remember:boolean }
  ): void
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { username: string, password: string, company_id: string }
  ) {
    let { username, password, company_id } = userInfo
    console.log('userInfo=>', userInfo)
    company_id = company_id.trim()
    username = username.toLowerCase()
    await loginRequest({ username, password, company_id }).then(async (res) => {
      console.log('res=======>', res)
      if (res?.code == 200) {
        setToken(res.data.token)
        commit(UserMutationTypes.SET_TOKEN, res.data.token)
      } else if (res?.code == 40007) {
        ElMessage({
          type: 'error',
          message: _t('user.DisableUser')
        })
      } else {
        // ElMessage({
        //   type: 'error',
        //   message: res?.msg
        // })
      }
    }).catch((err) => {
      let val = err?.response?.data
      if (val === undefined) {
        ElMessage({
          type: 'error',
          message: _t('system.qa.failRequeat')
        })
        return false
      }
      if (err.response.status === 401) {
        if (val.code === 40001) {
          ElMessage.closeAll()
          ElMessage({
            type: 'error',
            message: _t('system.companyNoExis')
          })
        } else if (val.code === 40002) {
          ElMessage.closeAll()
          ElMessage({
            type: 'error',
            message: _t('system.userNoExis')
          })
        } else if (val.code === 400031 || val.code === 400032 || val.code === 400033 || val.code === 400034 || val.code === 400035) {
          let num = ''
          switch (val.code) {
            case 400031:
              num = '1'
              break
            case 400032:
              num = '2'
              break
            case 400033:
              num = '3'
              break
            case 400034:
              num = '4'
              break
            default:
              num = '5';
              break;
          }
          ElMessage.closeAll()
          ElMessage({
            type: 'error',
            message: _t('system.passwordNoExis').replace('N', num)
          })
        } else {
          ElMessage.closeAll()
          ElMessage({
            type: 'error',
            message: val.msg
          })
        }
      } else {
        ElMessage.closeAll()
        ElMessage({
          type: 'error',
          message: val.msg
        })
      }
    })
  },

  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext) {
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
  },

  async [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext
  ) {
    if (state.token === '') {
      throw Error('token is undefined!')
    }
    await userInfoRequest().then((res: any) => {
      if (res?.code == 200) {
        let roles = res.data.username.split() // roles支持多个角色，类型是数组，由于目前只有一个角色，所以特地转化为数组
        commit(UserMutationTypes.SET_ROLES, roles)
        commit(UserMutationTypes.SET_NAME, res.data.username)
        commit(UserMutationTypes.SET_COMPANY, res.data.company_id)
        commit(UserMutationTypes.SET_AVATAR, userImg)
        console.log(res.data.authority, 'chakan')
        commit(UserMutationTypes.SET_Authority, res.data.authority)
        commit(UserMutationTypes.SET_EMAIL, res.data.email);
        // commit(UserMutationTypes.SET_INTRODUCTION, res.data.introduction)
        // commit(UserMutationTypes.SET_EMAIL, res.data.email)
        return res
      } else {
        // throw Error('Verification failed, please Login again.')
        router.push('/login')
      }
    })
  },

  async [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ) {
    const token = role + '-token'
    const store = useStore()
    commit(UserMutationTypes.SET_TOKEN, token)
    setToken(token)
    await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined)
    store.dispatch(PermissionActionType.ACTION_SET_ROUTES, state.roles)
    store.state.permission.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item)
    })
  },

  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext
  ) {
    console.log(commit)
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
    resetRouter()
  },

  async [UserActionTypes.ACTION_LOGIN_EMAIL]({commit}:AugmentedActionContext,
    info:{company_id:string,email:string,token:string,remember:boolean}
  ){
    if(info.remember) setRemember(info.company_id,info.email);
    setToken(info.token);
    commit(UserMutationTypes.SET_TOKEN,info.token);
  }

  // async [UserActionTypes.ACTION_LOGIN_EMAIL](
  //   { commit }: AugmentedActionContext,
  //   userInfo: { email: string, password: string, company_id: string, need: boolean, code: string, remember: boolean }
  // ) {
  //   let { email, password, company_id, need, code, remember } = userInfo;
  //   company_id = company_id.trim();
  //   email = email.toLowerCase();
  //   await loginEmailRequest({ email, password, company_id, need, code }).then(async (res) => {
  //     if (need && code == '') {
  //       if (res?.code == 200) {
  //         // 需要发送邮件，且发送成功
  //         return;
  //       } else if (res?.code == 40007) {
  //         ElMessage({ type: 'error', message: _t('user.DisableUser') })
  //       } else {
  //         // 需要发送邮件，返回状态失败
  //         ElMessage({ type: 'error', message: _t('system.qa.failRequeat') })
  //         return;
  //       }
  //     } else {
  //       if (res?.code == 200 && res.data.token) {
  //         if (remember) setRemember();
  //         setToken(res.data.token);
  //         commit(UserMutationTypes.SET_TOKEN, res.data.token);
  //       } else if (res?.code == 40007) {
  //         ElMessage({ type: 'error', message: _t('user.DisableUser') })
  //       } else {
  //         ElMessage({ type: 'error', message: _t('system.qa.failRequeat') });
  //       }
  //     }
  //   }).catch((err) => {
  //     console.log('*******', err);
  //     ElMessage({ type: 'error', message: _t('system.qa.failRequeat') });
  //   })
  // },
}
