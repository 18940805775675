/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-6 18:01:19
 */
import moment from 'moment'
import i18n from '@/locales/index'
import { getLanguage } from '@/utils/cookies'
const $t = i18n.global.t

export const getHM = (value?: any) => {
	if (value == 0) { return value}
	let _h = value/3600
	const tempTime = moment.duration(value, 's');
	const h = tempTime.hours() ? Math.floor(_h) + $t('robot.hours') : ''
	const m = tempTime.minutes() ? tempTime.minutes() + $t('robot.minutes') : ''
	const t = tempTime.seconds() ? tempTime.seconds() + $t('robot.seconds') : ''
	return h + m + t;
}

export const getHMS = (time?: any) => {
	if (isNaN(Number(time))) return time 
	const h = time >= 3600 ? Math.floor(time/3600) : 0
  const min = (time-h*3600) >= 60 ? Math.floor((time-h*3600)/60) : 0
  const second = time-h*3600-min*60
  let str = h>0 ? String(h).padStart(2, '0') + ':' : '00:'
      str = str + (min>0 ? String(min).padStart(2, '0') + ':' : '00:')
      str = str + (second>0 ? String(second).padStart(2, '0') : '00')
  return str
}


export const totalImg = () => {
  const files = require.context('@/assets/images/home', false, /\.png|.svg$/);
  const images = {};
  files.keys().forEach(key => {
    // images[key.replace(/(\.\/|\.png)/g, "")] = '@/static/img'+key.substring(1, key.length);
    images[key.replace(/(\.\/|\.png|.svg)/g, '')] = files(key);
  });
  return images;
}

export const setRobotName = (val?: any) => {
	if (val == undefined) return 
	const a = val.substring(0,10),
	  	len = val.length,
			b = val.substring(len-4,len);
	const c = (a + b).toUpperCase()
	return c
}
export const disinfectType = (key?: any) => {
	let val = ''
	switch(key) {
		case 'disinfect':
			val = $t('robot.InSitu_disinfect')
			break
		case 'explore_disinfect':
			val = $t('robot.explore_disinfect')
			break  
		case 'deploy_disinfect':
			val = $t('robot.deploy_disinfect')
			break  
		default:
			val = key;
		break;
	}
	return val
}
export const getStep = (item?: any, type?: any) => {
	const n = item.phase_type, status = item.status, detail = item.detail
	const arr = [
		{key: 'start', val: $t('robot.start')},
		{key: 'dock', val: $t('robot.dock')},
		{key: 'leave_dock', val: $t('robot.leave_dock')},
		{key: 'locomotion', val: $t('robot.locomotion')},
		{key: 'location', val: $t('robot.location')},
		{key: 'navigation', val: $t('robot.navigation')},
		{key: 'navigation_dock', val: $t('robot.navigation_dock')},
		{key: 'navigation_origin', val: $t('robot.navigation_origin')},
		{key: 'audio', val: $t('robot.voicebroadcast')},
		{key: 'countdown', val: $t('robot.countdown')},
		{key: 'disinfect', val: $t('robot.disinfect')},
		{key: 'explore', val: $t('robot.explore')},
		{key: 'sleep', val: $t('robot.sleep')},
		{key: 'charging', val: $t('robot.charging')},
		{key: 'pause', val: $t('robot.pause')},
		{key: 'end', val: $t('robot.end')},
	]
	const _arr = [
		{key: 'idle', val: $t('robot.idle')},
		{key: 'start', val: $t('robot.in_operation')},
		{key: 'pause', val: $t('robot.pause')},
		{key: 'hangup', val: $t('robot.hangup')},
		{key: 'failed', val: $t('robot.failed')},
		{key: 'canceled', val: $t('robot.canceled')},
		{key: 'finish', val: $t('robot.finish')},
	]
	const list = [
		{key: 'safeguard', val: $t('robot.safeguard')},
		{key: 'manual', val: $t('robot.manual')},
		{key: 'safeguard_pir', val: $t('robot.safeguard_pir')},
		{key: 'safeguard_ai', val: $t('robot.safeguard_ai')},
		{key: 'safeguard_access_control', val: $t('robot.safeguard_access_control')},
		{key: 'access_control', val: $t('robot.access_control')},
		{key: 'high_temperature', val: $t('robot.high_temperature')},
	]
	let text = '', color = '', _text = '', t = ''
	arr.map(item => {
		if (item.key == n) {
			text = item.val
		}
	})
	list.map(item => {
		if (item.key == detail) {
			t = item.val
		}
	})
	_arr.map(item => {
		if (item.key == status && item.key != 'finish') {
			if (item.key == 'pause') {
				_text = t
			} else {
				_text = item.val
			}
		}
	})
	switch(n) {
		case 'pause':
			color = '#FFA500';
			break
		case 'disinfect':
			color = '#4caf50';
			break  
		case 'failed':
			color = '#FF0000';
			break  
		case 'start':
			color = '#000';
			break  
		case 'end':
			color = '#000';
			break  
		default:
			color = '#c0c5cc';
		break;
	}
	
	return type == 0 ? color : text + ' ' + _text
}
export const valueFilter = (n?:any, obj?:any) => {
  let arr = Object.keys(obj),
      val = '';
  arr.forEach(li => {
    if (li == n) {
      val = obj[li];
    }
  });
  return val;
};

export const momentStamp = (type) => {
	const lan = getLanguage()
	// MMM Do YYYY, h:mm:ss a
	if(lan === 'en'){
		return type==='dateTime' ? 'MMM Do YYYY, HH:mm:ss'
					 : type==='date' ? 'MMM Do YYYY'
					 : type==='time' ? 'HH:mm:ss'
					 : 'MMM Do YYYY, HH:mm:ss'
	} else {
		return type==='dateTime' ? 'YYYY-MM-DD HH:mm:ss'
					 : type==='date' ? 'YYYY-MM-DD'
					 : type==='time' ? 'HH:mm:ss'
					 : 'YYYY-MM-DD HH:mm:ss'
	}
}
