/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-07 16:16:40
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @LastEditTime: 2022-07-12 18:38:19
 */


import project from './en/project'
import global from './en/global'
import system from './en/system'
import user from './en/user'

export default {
  ...project,
  ...global,
  ...system,
  ...user,
}
