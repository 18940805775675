/*
 * @Description: ADIS Web
 * @Author: 郭长生 wb-changsheng.guo@ubtrobot.com
 * @Date: 2024-02-20 10:34:33
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-02-20 11:16:06
 */

import project from "./es/project";
import global from "./es/global";
import system from "./es/system";
import user from "./es/user";

export default {
  ...project,
  ...global,
  ...system,
  ...user
};
