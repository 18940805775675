/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-12 15:29:01
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @LastEditTime: 2022-07-06 15:45:35
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const userRouter: Array<RouteRecordRaw> = [
  {
    path: '/user',
    component: Layout,
    redirect: 'noredirect',
    meta: {
      title: 'user',
      icon: '#iconcomponent',
      roles: ['admin']
    },
    children: [
      {
        path: 'manage',
        component: () => import('@/views/user/Manage.vue'),
        name: 'manage',
        meta: {
          title: 'manage',
          icon: 'manageNav',
          roles: ['admin']
        }
      }
    ]
  }
]

export default userRouter
