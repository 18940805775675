
import Keys from '@/constant/key'
import Cookies from 'js-cookie'

export const getSidebarStatus = () => Cookies.get(Keys.sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(Keys.sidebarStatusKey, sidebarStatus)

export const getLanguage = () => Cookies.get(Keys.languageKey)
export const setLanguage = (language: string) => Cookies.set(Keys.languageKey, language)

export const getSize = () => Cookies.get(Keys.sizeKey)
export const setSize = (size: string) => Cookies.set(Keys.sizeKey, size)

export const getToken = () => Cookies.get(Keys.tokenKey)
export const setToken = (token: string) => Cookies.set(Keys.tokenKey, token)
export const removeToken = () => Cookies.remove(Keys.tokenKey)

export const getRemember = (companyid:string,email:string): boolean => {
    const now = Date.now();
    const key=`${Keys.rememberKey}-${companyid}-${email}`;
    const value = Cookies.get(key);
    if (value == '' || value == undefined) return false;
    const ago = parseInt(value, 10);
    if (now - ago > 30 * 24 * 60 * 60 * 1e3) {
        Cookies.remove(key);
        return false
    }
    return true;
}
export const setRemember = (companyid:string,email:string) => {
    const now = Date.now();
    const key=`${Keys.rememberKey}-${companyid}-${email}`;
    let expires = new Date(now + 1e3 * 60 * 60 * 24 * 30);
    Cookies.set(key, `${now}`, { expires: expires });
};