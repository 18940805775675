/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-08 17:10:19
 * @LastEditors: zhongbin.chen
 * @LastEditTime: 2022-01-19 11:41:26
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: '/robot',
    component: Layout,
    redirect: 'robotList',
    // meta: { hidden: true },
    children: [
      {
        path: 'robotList',
        component: () => import('@/views/Robot/Robot.vue'),
        name: 'robot',
        meta: {
          title: 'robot',
          icon: 'robotNav'
        }
      },
      {
        path: 'robotInfo/:sn',
        component: () => import('@/views/Robot/RobotInfo.vue'),
        name: '机器人详情',
        meta: {
          title: '机器人详情',
          noCache: true,
          hidden: true
        }
      },
      {
        path: 'taskInfo/:report',
        component: () => import('@/views/Robot/taskInfo.vue'),
        name: '消杀任务详情',
        meta: {
          title: '消杀任务详情',
          noCache: true,
          hidden: true
        }
      },{
        path: 'taskstatus/:sn',
        component: () => import('@/views/Robot/createDetail.vue'),
        name: '创建任务',
        meta: {
          title: '创建任务',
          noCache: true,
          hidden: true
        }
      },
      {
        path: 'timingdetail/:sn',
        component: () => import('@/views/Robot/cretetimingdetail.vue'),
        name: '创建定时任务',
        meta: {
          title: '创建定时任务',
          noCache: true,
          hidden: true
        }
      },
      {
        path: 'EditingScheduled/:sn/:taskid/:type',
        component: () => import('@/views/Robot/EditingScheduled.vue'),
        name: '编辑定时任务',
        meta: {
          title: '编辑定时任务',
          noCache: true,
          hidden: true
        }
      },
    ]
  },
]

export default ExampleRouter
