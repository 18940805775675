
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import settings from "@/config/default/setting.config";
import Logo from "@/assets/images/home/logo.png";
export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useStore();
    const title = settings.title;
    const language = computed(() => {
      return store.state.app.language;
    });
    const logoSize = computed((): string => {
      switch (store.state.app.language) {
        case "en":
          return "13px";
        case 'es':
          return "12px";
        default:
          return "16px";
      }
    });
    return {
      title,
      Logo,
      language,
      logoSize
    };
  }
});
